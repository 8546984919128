import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import './borrow.css'
import { ThemeContext } from '../../state/ThemeContext'
import altsLogo from '../../assets/images/ALTS_logo.png'
import longLogo from '../../assets/images/LONG_logo.png'
import eth from '../../assets/images/eth.png'
import NumberFormat from 'react-number-format'

const Borrow = () => {
  const { isDarkMode } = useContext(ThemeContext)

  const CollateralCard = ({
    hasBorder,
    collateralIcon,
    collateralIcon2,
    collateralName,
    totalBorrowed,
    totalLeftToBorrow,
    interest,
    borrowFee,
    liquidationPenalty,
    maximumLTVRatio,
    lpToken,
    url,
  }) => {
    return (
      <div
        className={`${
          !isDarkMode
            ? 'collateral-card-container'
            : 'collateral-card-container-dark-mode'
        } vault-card`}
      >
        {lpToken ? (
          <div className="borrow-logos">
            <img
              src={collateralIcon}
              width="60"
              height="60"
              alt={collateralName}
              className="trade-icon-2"
              style={{
                border: hasBorder && '2px solid #0066ff',
                backgroundColor: '#ffffff',
                marginRight: '10px',
              }}
            />
            <img
              src={collateralIcon2}
              width="60"
              height="60"
              alt={collateralName}
              className="trade-icon-2"
              style={{
                backgroundColor: '#ffffff',
              }}
            />
          </div>
        ) : (
          <img
            src={collateralIcon}
            width="60"
            height="60"
            alt={collateralName}
            className="trade-icon-2"
            style={{
              border: hasBorder && '2px solid #0066ff',
              backgroundColor: '#ffffff',
            }}
          />
        )}

        <h1 className="trade-card-title collateral-card-title">
          {collateralName}
        </h1>
        <br />
        <p className="trade-card-dex">
          <strong>Total Borrowed:</strong> {totalBorrowed} DAI
        </p>
        <p className="trade-card-dex">
          <strong>Total Left to Borrow:</strong> {totalLeftToBorrow} DAI
        </p>
        <p className="trade-card-dex text-center">
          <strong>Interest:</strong> {interest}% (Fixed)
        </p>
        <p className="trade-card-dex text-center">
          <strong>Borrow Fee:</strong> {borrowFee}%
        </p>
        <p className="trade-card-dex text-center">
          <strong>Liquidation Penalty:</strong> {liquidationPenalty}%
        </p>
        <p className="trade-card-dex text-center">
          <strong>Maximum LTV Ratio:</strong> {maximumLTVRatio}%
        </p>
        <br />
        <div className="trading-buttons">
          <button className="btn regular-btn border-rad-05 ml-1" type="button">
            <Link
              to={`/borrow/${url}`}
              className="trade-card-analytics-link"
              style={{
                color: 'white',
                fontWeight: 'bold',
                fontSize: '1.175rem',
              }}
            >
              Borrow DAI
            </Link>
          </button>
        </div>
      </div>
    )
  }

  return (
    <div
      className={`${
        isDarkMode ? 'trade-container-dark-mode' : 'trade-container'
      }`}
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="borrow-intro-container">
        <br />
        <br />

        <div className="borrow-logos">
          <img
            src={altsLogo}
            alt="ALTS"
            width="60"
            height="60"
            className="token-img mr-5 no-cursor-pointer"
            style={{
              backgroundColor: '#ffffff',
            }}
          />
          <img
            src={longLogo}
            alt="LONG"
            width="60"
            height="60"
            className="token-img mr-5 no-cursor-pointer"
            style={{
              backgroundColor: '#ffffff',
            }}
          />
          <img src={eth} alt="ETH" width="60" height="60" />
        </div>

        <h1
          className="text-center bold mb-4 mt-4"
          style={{ fontSize: '2.75rem' }}
        >
          Borrow DAI (Coming Soon!)
        </h1>

        <h2 className="text-center bold mb-4">
          Borrow DAI against your ALTS, LONG and their LP tokens
        </h2>

        <p
          className={`text-mute text-center ml-5 mr-5 mb-5 ${
            isDarkMode ? 'early-adopter-note-dark-mode' : 'early-adopter-note'
          }`}
        >
          There is no need to sell your precious long-term holdings when you can
          borrow DAI stablecoin against them at{' '}
          <strong>a guaranteed fixed interest rate of 5%.</strong> There's also{' '}
          <strong>
            the additional benefit of being able to borrow against your LP
            tokens,
          </strong>{' '}
          which accrue interest in real time from the swap fees on DEXs and{' '}
          <strong>make your loans self-repaying.</strong> To learn more, please
          check out{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.long-term.finance/protocol/lend-and-borrow"
            style={{ color: '#0066ff' }}
            className="doc-link"
          >
            <strong>our official documentation.</strong>
          </a>
        </p>
      </div>

      <br />
      <br />

      <h1
        className="text-center bold mb-4 mt-4"
        style={{ fontSize: '2.75rem' }}
      >
        Pick Your Collateral
      </h1>

      <br />

      <div className="trade-grid">
        <CollateralCard
          hasBorder
          collateralIcon={altsLogo}
          collateralName={'ALTS'}
          totalBorrowed={
            <NumberFormat
              className="number"
              value={0}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          totalLeftToBorrow={
            <NumberFormat
              className="number"
              value={0}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          interest={5}
          borrowFee={1}
          liquidationPenalty={5}
          maximumLTVRatio={75}
          url={'ALTS'}
        />
        <CollateralCard
          hasBorder
          lpToken
          collateralIcon={altsLogo}
          collateralIcon2={eth}
          collateralName={'ALTS / WETH SLP'}
          totalBorrowed={
            <NumberFormat
              className="number"
              value={0}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          totalLeftToBorrow={
            <NumberFormat
              className="number"
              value={0}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          interest={5}
          borrowFee={1}
          liquidationPenalty={5}
          maximumLTVRatio={75}
          url={'ALTS-WETH'}
        />
        <CollateralCard
          hasBorder
          collateralIcon={longLogo}
          collateralName={'LONG'}
          totalBorrowed={
            <NumberFormat
              className="number"
              value={0}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          totalLeftToBorrow={
            <NumberFormat
              className="number"
              value={0}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          interest={5}
          borrowFee={1}
          liquidationPenalty={5}
          maximumLTVRatio={75}
          url={'LONG'}
        />
        <CollateralCard
          hasBorder
          lpToken
          collateralIcon={longLogo}
          collateralIcon2={eth}
          collateralName={'LONG / WETH SLP'}
          totalBorrowed={
            <NumberFormat
              className="number"
              value={0}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          totalLeftToBorrow={
            <NumberFormat
              className="number"
              value={0}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          interest={5}
          borrowFee={1}
          liquidationPenalty={5}
          maximumLTVRatio={75}
          url={'LONG-WETH'}
        />
      </div>

      <br />
      <br />
      <br />
      <br />
    </div>
  )
}

export default Borrow
