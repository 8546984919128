import React, { useContext } from 'react'
import './borrow.css'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../../state/ThemeContext'
import alts from '../../assets/images/ALTS_logo.png'
import dai from '../../assets/images/dai.png'

const BorrowALTS = () => {
  const { isDarkMode } = useContext(ThemeContext)

  const approveFn = () => {
    alert('Coming Soon!')
  }

  return (
    <div
      className={`${
        isDarkMode ? 'trade-container-dark-mode' : 'trade-container'
      }`}
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <button className="btn regular-btn bold border-rad-05 back-to-vaults-btn">
        <Link
          to="/borrow"
          className="trade-card-analytics-link"
          style={{
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.175rem',
          }}
          type="button"
        >
          ↩ Pick Another Collateral
        </Link>
      </button>

      <div className="create-vault-container ">
        <div className="borrow-logos">
          <img
            src={dai}
            alt="DAI"
            width="70"
            height="70"
            style={{
              marginRight: '25px',
            }}
          />
          <img
            src={alts}
            alt="ALTS"
            width="70"
            height="70"
            className="token-img no-cursor-pointer"
            style={{
              backgroundColor: '#ffffff',
            }}
          />
        </div>

        <h1
          className="text-center bold mb-5 mt-4"
          style={{ fontSize: '2.75rem' }}
        >
          Borrow DAI Using ALTS as Collateral
        </h1>

        <h2 className="text-center bold create-vault-title">Approve ALTS</h2>

        <p className="text-center create-vault-subtitle">
          ALTS not approved yet. Please click on "Approve ALTS" to approve the
          ALTS token as collateral and start borrowing DAI stablecoins against
          it.
          <br /> <br /> <br /> <br />
          <span
            style={{
              fontSize: '1.5rem',
            }}
          >
            <span
              style={{
                textDecoration: 'underline',
                fontWeight: 'bold',
              }}
            >
              NOTE:
            </span>{' '}
            Make sure your wallet is connected and it's{' '}
            <Link
              to="/add-polygon"
              className="trade-card-analytics-link doc-link"
              style={{
                color: '#0066ff',
                fontWeight: 'bold',
              }}
            >
              on the Polygon (Matic) mainnet.
            </Link>
          </span>
        </p>
        <button
          className="btn regular-btn create-vault-btn"
          type="button"
          onClick={approveFn}
        >
          Approve ALTS
        </button>
      </div>
    </div>
  )
}

export default BorrowALTS
