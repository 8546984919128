import React from 'react'

export const TOKENS_DEFAULT_VALUE = {
  setCurrentETHToken: () => {},
  setCurrentBTCToken: () => {},
  setCurrentMIMToken: () => {},
  setCurrentALTSToken: () => {},
  setCurrentLongToken: () => {},

  setCurrentALTSPoolToken: () => {}, // ALTS/ETH LP
  setCurrentLONGPoolToken: () => {}, // LONG/ETH LP

  setCurrentETHTokenRead: () => {},
  setCurrentBTCTokenRead: () => {},
  setCurrentMIMTokenRead: () => {},
  setCurrentALTSTokenRead: () => {},
  setCurrentLongTokenRead: () => {},

  setCurrentALTSPoolTokenRead: () => {}, // ALTS/ETH LP
  setCurrentLONGPoolTokenRead: () => {}, // LONG/ETH LP
}

const tokensContext = React.createContext(TOKENS_DEFAULT_VALUE)

export default tokensContext
