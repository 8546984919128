import { useState, useCallback } from 'react'

export const useRewards = () => {
  const [ethReward, setETHReward] = useState()
  const [btcReward, setBTCReward] = useState()
  const [mimReward, setMIMReward] = useState()

  const [altsPoolReward, setALTSPoolReward] = useState()
  const [longPoolReward, setLONGPoolReward] = useState()

  const [ethRewardRead, setETHRewardRead] = useState()
  const [btcRewardRead, setBTCRewardRead] = useState()
  const [mimRewardRead, setMIMRewardRead] = useState()

  const [altsPoolRewardRead, setALTSPoolRewardRead] = useState()
  const [longPoolRewardRead, setLONGPoolRewardRead] = useState()

  const setCurrentETHReward = useCallback((currentETHReward) => {
    setETHReward(currentETHReward)
  }, [])
  const setCurrentBTCReward = useCallback((currentBTCReward) => {
    setBTCReward(currentBTCReward)
  }, [])
  const setCurrentMIMReward = useCallback((currentMIMReward) => {
    setMIMReward(currentMIMReward)
  }, [])

  const setCurrentALTSPoolReward = useCallback((currentALTSPoolReward) => {
    setALTSPoolReward(currentALTSPoolReward)
  }, [])
  const setCurrentLONGPoolReward = useCallback((currentLONGPoolReward) => {
    setLONGPoolReward(currentLONGPoolReward)
  }, [])

  const setCurrentETHRewardRead = useCallback((currentETHReward) => {
    setETHRewardRead(currentETHReward)
  }, [])
  const setCurrentBTCRewardRead = useCallback((currentBTCReward) => {
    setBTCRewardRead(currentBTCReward)
  }, [])
  const setCurrentMIMRewardRead = useCallback((currentMIMReward) => {
    setMIMRewardRead(currentMIMReward)
  }, [])

  const setCurrentALTSPoolRewardRead = useCallback((currentALTSPoolReward) => {
    setALTSPoolRewardRead(currentALTSPoolReward)
  }, [])
  const setCurrentLONGPoolRewardRead = useCallback((currentLONGPoolReward) => {
    setLONGPoolRewardRead(currentLONGPoolReward)
  }, [])

  return {
    ethReward,
    setCurrentETHReward,
    btcReward,
    setCurrentBTCReward,
    mimReward,
    setCurrentMIMReward,

    altsPoolReward,
    setCurrentALTSPoolReward,
    longPoolReward,
    setCurrentLONGPoolReward,

    ethRewardRead,
    setCurrentETHRewardRead,
    btcRewardRead,
    setCurrentBTCRewardRead,
    mimRewardRead,
    setCurrentMIMRewardRead,

    altsPoolRewardRead,
    setCurrentALTSPoolRewardRead,
    longPoolRewardRead,
    setCurrentLONGPoolRewardRead,
  }
}
