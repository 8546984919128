import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../../state/ThemeContext'
import matic from '../../assets/images/matic.png'
import eth from '../../assets/images/eth.png'
import btc from '../../assets/images/renBTC.png'
import dai from '../../assets/images/dai.png'
import mim from '../../assets/images/MIM.png'
import ust from '../../assets/images/UST.png'
import './vault.css'
import { RPC_URL } from '../../utils/constants'
import { ethers } from 'ethers'
import NumberFormat from 'react-number-format'
import longtermJson from '../../contracts/longterm.json'

const Vaults = () => {
  const { isDarkMode } = useContext(ThemeContext)

  const [isLoading, setIsLoading] = useState(true)
  const [maticTvl, setMaticTvl] = useState('0.00')
  const [ethTvl, setEthTvl] = useState('0.00')
  const [btcTvl, setBtcTvl] = useState('0.00')
  const [daiTvl, setDaiTvl] = useState('0.00')
  const [mimTvl, setMimTvl] = useState('0.00')
  const [ustTvl, setUstTvl] = useState('0.00')
  const [maticTvlUsd, setMaticTvlUsd] = useState('0.00')
  const [ethTvlUsd, setEthTvlUsd] = useState('0.00')
  const [btcTvlUsd, setBtcTvlUsd] = useState('0.00')
  const [daiTvlUsd, setDaiTvlUsd] = useState('0.00')
  const [mimTvlUsd, setMimTvlUsd] = useState('0.00')
  const [ustTvlUsd, setUstTvlUsd] = useState('0.00')
  const [totalMaticStake, setTotalMaticStake] = useState([])
  const [totalEthStake, setTotalEthStake] = useState([])
  const [totalBtcStake, setTotalBtcStake] = useState([])
  const [totalDaiStake, setTotalDaiStake] = useState([])
  const [totalMimStake, setTotalMimStake] = useState([])
  const [totalUstStake, setTotalUstStake] = useState([])

  useEffect(() => {
    const loadData = async () => {
      const provider = new ethers.providers.JsonRpcProvider(RPC_URL)
      const contracts = longtermJson[137].matic.contracts

      // Set required contracts
      const MATICOracle = new ethers.Contract(
        contracts.MATICOracle.address,
        contracts.MATICOracle.abi,
        provider,
      )

      const ETHOracle = new ethers.Contract(
        contracts.ETHOracle.address,
        contracts.ETHOracle.abi,
        provider,
      )

      const BTCOracle = new ethers.Contract(
        contracts.BTCOracle.address,
        contracts.BTCOracle.abi,
        provider,
      )

      const DAIOracle = new ethers.Contract(
        contracts.DAIOracle.address,
        contracts.DAIOracle.abi,
        provider,
      )

      const MIMOracle = new ethers.Contract(
        contracts.MIMOracle.address,
        contracts.MIMOracle.abi,
        provider,
      )

      const USTOracle = new ethers.Contract(
        contracts.USTOracle.address,
        contracts.USTOracle.abi,
        provider,
      )

      const MATICVault = new ethers.Contract(
        contracts.MATICVaultHandler.address,
        contracts.MATICVaultHandler.abi,
        provider,
      )

      const ETHVault = new ethers.Contract(
        contracts.ETHVaultHandler.address,
        contracts.ETHVaultHandler.abi,
        provider,
      )

      const BTCVault = new ethers.Contract(
        contracts.BTCVaultHandler.address,
        contracts.BTCVaultHandler.abi,
        provider,
      )

      const DAIVault = new ethers.Contract(
        contracts.DAIVaultHandler.address,
        contracts.DAIVaultHandler.abi,
        provider,
      )

      const MIMVault = new ethers.Contract(
        contracts.MIMVaultHandler.address,
        contracts.MIMVaultHandler.abi,
        provider,
      )

      const USTVault = new ethers.Contract(
        contracts.USTVaultHandler.address,
        contracts.USTVaultHandler.abi,
        provider,
      )

      // Get oracle prices
      const maticUSDRes = await MATICOracle.getLatestAnswer()
      const maticUSD = ethers.utils.formatUnits(maticUSDRes.toString(), 8)

      const ethUSDRes = await ETHOracle.getLatestAnswer()
      const ethUSD = ethers.utils.formatUnits(ethUSDRes.toString(), 8)

      const btcUSDRes = await BTCOracle.getLatestAnswer()
      const btcUSD = ethers.utils.formatUnits(btcUSDRes.toString(), 8)

      const daiUSDRes = await DAIOracle.getLatestAnswer()
      const daiUSD = ethers.utils.formatUnits(daiUSDRes.toString(), 8)

      const mimUSDRes = await MIMOracle.getLatestAnswer()
      const mimUSD = ethers.utils.formatUnits(mimUSDRes.toString(), 8)

      const ustUSDRes = await USTOracle.getLatestAnswer()
      const ustUSD = ethers.utils.formatUnits(ustUSDRes.toString(), 8)

      // TVL in MATIC
      const currentCounterMatic = await MATICVault.counter()
      const currentCounterIntMatic = parseInt(
        ethers.utils.formatEther(
          ethers.utils.parseEther(currentCounterMatic.toString()).toString(),
        ),
      )

      for (let c = 1; c <= currentCounterIntMatic - 1; c++) {
        let currentVault = await MATICVault.vaults(c)

        let currentVaultCollateral = currentVault[1].toString()

        let currentVaultCollateralParsed = parseFloat(
          ethers.utils.formatEther(currentVaultCollateral),
        )

        totalMaticStake.push(currentVaultCollateralParsed)
      }

      setTotalMaticStake(totalMaticStake)
      const slicedTotalMaticStake = totalMaticStake.slice(
        0,
        currentCounterIntMatic - 1,
      )

      const currentMaticTvl = slicedTotalMaticStake.reduce((a, c) => a + c, 0)
      setMaticTvl(currentMaticTvl)

      const currentMaticTvlUsd = parseFloat(currentMaticTvl * maticUSD)
      setMaticTvlUsd(currentMaticTvlUsd)

      // TVL in WETH
      const currentCounter = await ETHVault.counter()
      const currentCounterInt = parseInt(
        ethers.utils.formatEther(
          ethers.utils.parseEther(currentCounter.toString()).toString(),
        ),
      )

      for (let c = 1; c <= currentCounterInt - 1; c++) {
        let currentVault = await ETHVault.vaults(c)

        let currentVaultCollateral = currentVault[1].toString()

        let currentVaultCollateralParsed = parseFloat(
          ethers.utils.formatEther(currentVaultCollateral),
        )

        totalEthStake.push(currentVaultCollateralParsed)
      }

      setTotalEthStake(totalEthStake)
      const slicedTotalEthStake = totalEthStake.slice(0, currentCounterInt - 1)

      const currentEthTvl = slicedTotalEthStake.reduce((a, c) => a + c, 0)
      setEthTvl(currentEthTvl)

      const currentEthTvlUsd = parseFloat(currentEthTvl * ethUSD)
      setEthTvlUsd(currentEthTvlUsd)

      // TVL in renBTC
      const currentCounterBtc = await BTCVault.counter()
      const currentCounterIntBtc = parseInt(
        ethers.utils.formatEther(
          ethers.utils.parseEther(currentCounterBtc.toString()).toString(),
        ),
      )

      for (let c = 1; c <= currentCounterIntBtc - 1; c++) {
        let currentVault = await BTCVault.vaults(c)

        let currentVaultCollateral = currentVault[1].toString()

        let currentVaultCollateralParsed = parseFloat(
          ethers.utils.formatEther(currentVaultCollateral),
        )

        totalBtcStake.push(currentVaultCollateralParsed)
      }

      setTotalBtcStake(totalBtcStake)
      const slicedTotalBtcStake = totalBtcStake.slice(
        0,
        currentCounterIntBtc - 1,
      )

      const currentBtcTvl = slicedTotalBtcStake.reduce((a, c) => a + c, 0)
      setBtcTvl(currentBtcTvl)

      const currentBtcTvlUsd = parseFloat(currentBtcTvl * btcUSD)
      setBtcTvlUsd(currentBtcTvlUsd)

      // TVL in DAI
      const currentCounterDai = await DAIVault.counter()
      const currentCounterIntDai = parseInt(
        ethers.utils.formatEther(
          ethers.utils.parseEther(currentCounterDai.toString()).toString(),
        ),
      )

      for (let c = 1; c <= currentCounterIntDai - 1; c++) {
        let currentVault = await DAIVault.vaults(c)

        let currentVaultCollateral = currentVault[1].toString()

        let currentVaultCollateralParsed = parseFloat(
          ethers.utils.formatEther(currentVaultCollateral),
        )

        totalDaiStake.push(currentVaultCollateralParsed)
      }

      setTotalDaiStake(totalDaiStake)
      const slicedTotalDaiStake = totalDaiStake.slice(
        0,
        currentCounterIntDai - 1,
      )

      const currentDaiTvl = slicedTotalDaiStake.reduce((a, c) => a + c, 0)
      setDaiTvl(currentDaiTvl)

      const currentDaiTvlUsd = parseFloat(currentDaiTvl * daiUSD)
      setDaiTvlUsd(currentDaiTvlUsd)

      // TVL in MIM
      const currentCounterMim = await MIMVault.counter()
      const currentCounterIntMim = parseInt(
        ethers.utils.formatEther(
          ethers.utils.parseEther(currentCounterMim.toString()).toString(),
        ),
      )

      for (let c = 1; c <= currentCounterIntMim - 1; c++) {
        let currentVault = await MIMVault.vaults(c)

        let currentVaultCollateral = currentVault[1].toString()

        let currentVaultCollateralParsed = parseFloat(
          ethers.utils.formatEther(currentVaultCollateral),
        )

        totalMimStake.push(currentVaultCollateralParsed)
      }

      setTotalMimStake(totalMimStake)
      const slicedTotalMimStake = totalMimStake.slice(
        0,
        currentCounterIntMim - 1,
      )

      const currentMimTvl = slicedTotalMimStake.reduce((a, c) => a + c, 0)
      setMimTvl(currentMimTvl)

      const currentMimTvlUsd = parseFloat(currentMimTvl * mimUSD)
      setMimTvlUsd(currentMimTvlUsd)

      // TVL in UST
      const currentCounterUst = await USTVault.counter()
      const currentCounterIntUst = parseInt(
        ethers.utils.formatEther(
          ethers.utils.parseEther(currentCounterUst.toString()).toString(),
        ),
      )

      for (let c = 1; c <= currentCounterIntUst - 1; c++) {
        let currentVault = await USTVault.vaults(c)

        let currentVaultCollateral = currentVault[1].toString()

        let currentVaultCollateralParsed = parseFloat(
          ethers.utils.formatEther(currentVaultCollateral),
        )

        totalUstStake.push(currentVaultCollateralParsed)
      }

      setTotalUstStake(totalUstStake)
      const slicedTotalUstStake = totalUstStake.slice(
        0,
        currentCounterIntUst - 1,
      )

      const currentUstTvl = slicedTotalUstStake.reduce((a, c) => a + c, 0)
      setUstTvl(currentUstTvl)

      const currentUstTvlUsd = parseFloat(currentUstTvl * ustUSD)
      setUstTvlUsd(currentUstTvlUsd)

      setIsLoading(false)
    }

    loadData()
  }, [
    totalMaticStake,
    totalEthStake,
    totalBtcStake,
    totalDaiStake,
    totalMimStake,
    totalUstStake,
    maticTvl,
    ethTvl,
    btcTvl,
    daiTvl,
    mimTvl,
    ustTvl,
    maticTvlUsd,
    ethTvlUsd,
    btcTvlUsd,
    daiTvlUsd,
    mimTvlUsd,
    ustTvlUsd,
  ])

  const VaultCard = ({ vaultIcon, vaultName, tvl, tvlInUsd, hasBorder }) => {
    return (
      <div
        className={`${
          !isDarkMode
            ? 'trade-card-container-new'
            : 'trade-card-container-dark-mode-new'
        } vault-card`}
      >
        <img
          src={vaultIcon}
          width="60"
          height="60"
          alt={vaultName && vaultName.split(' ')[0]}
          className="trade-icon-2"
          style={{
            border: hasBorder && !isDarkMode ? '2px solid #8F5AE8' : 'none',
          }}
        />

        <h1 className="trade-card-title">{vaultName}</h1>
        <br />
        <p className="trade-card-dex">
          <strong>TVL:</strong> {tvl} {vaultName && vaultName.split(' ')[0]}
        </p>
        <p className="trade-card-dex text-center">
          <strong>TVL in USD:</strong> ${tvlInUsd}
        </p>
        <br />
        <div className="trading-buttons">
          <button className="btn regular-btn border-rad-05 ml-1" type="button">
            <Link
              to={`/vaults/${vaultName && vaultName.split(' ')[0]}`}
              className="trade-card-analytics-link"
              style={{
                color: 'white',
                fontWeight: 'bold',
                fontSize: '1.175rem',
              }}
            >
              Open Vault
            </Link>
          </button>
        </div>
      </div>
    )
  }

  return (
    <div
      className={`vaults-container ${
        isDarkMode ? 'trade-container-dark-mode' : 'trade-container'
      }`}
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <h1
        className="text-center bold mb-5 mt-5"
        style={{ fontSize: '2.75rem' }}
      >
        LongTerm Finance Vaults
      </h1>

      <p
        className={`text-mute text-center ml-5 mr-5 mb-5 ${
          isDarkMode ? 'early-adopter-note-dark-mode' : 'early-adopter-note'
        }`}
      >
        Pick any of the collateral options shown below in order to{' '}
        <strong>create a vault and start minting ALTS tokens.</strong> Current
        collateral options include MATIC, WETH, renBTC, DAI, MIM and UST, with
        many more to come!
      </p>

      <span
        className="text-center"
        style={{
          display: 'block',
          fontSize: '2rem',
          fontWeight: 'bold',
        }}
      >
        {isLoading && (
          <>
            <br />
            (Loading TVL...)
          </>
        )}
      </span>

      <br />
      <br />

      <div className="trade-grid">
        <VaultCard
          vaultIcon={matic}
          vaultName={'MATIC Vault'}
          tvl={
            <NumberFormat
              className="number"
              value={!isLoading ? maticTvl : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          tvlInUsd={
            <NumberFormat
              className="number"
              value={!isLoading ? maticTvlUsd : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
        />
        <VaultCard
          vaultIcon={eth}
          vaultName={'WETH Vault'}
          tvl={
            <NumberFormat
              className="number"
              value={!isLoading ? ethTvl : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          tvlInUsd={
            <NumberFormat
              className="number"
              value={!isLoading ? ethTvlUsd : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
        />
        <VaultCard
          vaultIcon={btc}
          vaultName={'renBTC Vault'}
          tvl={
            <NumberFormat
              className="number"
              value={!isLoading ? btcTvl : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          tvlInUsd={
            <NumberFormat
              className="number"
              value={!isLoading ? btcTvlUsd : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
        />
        <VaultCard
          vaultIcon={dai}
          vaultName={'DAI Vault'}
          tvl={
            <NumberFormat
              className="number"
              value={!isLoading ? daiTvl : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          tvlInUsd={
            <NumberFormat
              className="number"
              value={!isLoading ? daiTvlUsd : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
        />
        <VaultCard
          vaultIcon={mim}
          vaultName={'MIM Vault'}
          tvl={
            <NumberFormat
              className="number"
              value={!isLoading ? mimTvl : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          tvlInUsd={
            <NumberFormat
              className="number"
              value={!isLoading ? mimTvlUsd : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
        />
        <VaultCard
          vaultIcon={ust}
          vaultName={'UST Vault'}
          tvl={
            <NumberFormat
              className="number"
              value={!isLoading ? ustTvl : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          tvlInUsd={
            <NumberFormat
              className="number"
              value={!isLoading ? ustTvlUsd : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
        />
      </div>

      <br />
      <br />
      <br />
      <br />
    </div>
  )
}

export default Vaults
