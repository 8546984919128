import React, { useState, useEffect, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import './dashboard.css'
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import List from '@material-ui/core/List'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import Button from '@material-ui/core/Button'
import transparentPNGLogo from '../../assets/images/banner.png'
import vaultIcon from '../../assets/images/vault.svg'
import whiteVaultIcon from '../../assets/images/vault_white.svg'
import greyVaultIcon from '../../assets/images/vault_grey.svg'
import bull from '../../assets/images/bull.svg'
import whiteBull from '../../assets/images/bull_white.svg'
import greyBull from '../../assets/images/bull_grey.svg'
import { ThemeContext } from '../../state/ThemeContext'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import Tooltip from 'react-bootstrap/esm/Tooltip'
import SignerContext from '../../state/SignerContext'
import { Web3ModalContext } from '../../state/Web3ModalContext'
import { makeShortAddress } from '../../utils/utils'
import logoutIcon from '../../assets/images/logout.png'
import whiteLogoutIcon from '../../assets/images/white_logout.png'
import altsLogo from '../../assets/images/ALTS_logo.png'
import longLogo from '../../assets/images/LONG_logo.png'

const DashboardLayout = () => {
  const { switchTheme, isDarkMode } = useContext(ThemeContext)
  const web3Modal = useContext(Web3ModalContext)
  const signer = useContext(SignerContext)
  const [address, setAddress] = useState('')

  const Icon = ({ src, alt }) => (
    <img
      src={src}
      alt={alt}
      width={isDarkMode ? '33' : '42'}
      height={isDarkMode ? '33' : '42'}
      className="trade-icon-2"
    />
  )

  const copyCodeToClipboard = (e) => {
    e.preventDefault()
    // Create new element
    const el = document.createElement('textarea')
    // Set value (string to be copied)
    el.value = address
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '')
    document.body.appendChild(el)
    // Select text inside element
    el.select()
    // Copy text to clipboard
    document.execCommand('copy')
    // Remove temporary element
    document.body.removeChild(el)
  }

  useEffect(() => {
    const loadData = async () => {
      if (signer.signer) {
        const currentAddress = await signer.signer?.getAddress()
        setAddress(currentAddress)
      }

      window.addEventListener('load', async () => {
        if (web3Modal.cachedProvider) {
          await web3Modal.connect()
        }
      })
    }

    loadData()

    return () => {
      window.removeEventListener('load', async () => {
        if (web3Modal.cachedProvider) {
          await web3Modal.clearCachedProvider()
        }
      })
    }
    // eslint-disable-next-line
  }, [signer])

  const mobileHeader = useMediaQuery({ query: '(max-width: 992px)' })

  const Navbar = () => {
    const MoonIcon = () => {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill={isDarkMode ? 'none' : '#000'}
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
        </svg>
      )
    }

    const SunIcon = () => {
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="5"></circle>
          <line x1="12" y1="1" x2="12" y2="3"></line>
          <line x1="12" y1="21" x2="12" y2="23"></line>
          <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
          <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
          <line x1="1" y1="12" x2="3" y2="12"></line>
          <line x1="21" y1="12" x2="23" y2="12"></line>
          <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
          <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
        </svg>
      )
    }

    const ModeSwitcher = () => {
      return (
        <span className={!mobileHeader && 'ml-2 mr-3'}>
          <button
            type="button"
            onClick={switchTheme}
            className={`mode-switcher ${!isDarkMode && 'mode-switcher-moon'} ${
              mobileHeader && 'ml-5'
            }`}
            style={{
              marginLeft: mobileHeader && '18px',
              marginBottom: mobileHeader && '10px',
            }}
          >
            {isDarkMode ? <SunIcon /> : <MoonIcon />}
          </button>
        </span>
      )
    }

    const useStyles = makeStyles({
      list: {
        width: 250,
      },
      fullList: {
        width: 'auto',
      },
    })

    function SwipeableTemporaryDrawer() {
      const classes = useStyles()
      const [state, setState] = useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      })

      const toggleDrawer = (anchor, open) => (event) => {
        if (
          event &&
          event.type === 'keydown' &&
          (event.key === 'Tab' || event.key === 'Shift')
        ) {
          return
        }

        setState({ ...state, [anchor]: open })
      }

      const list = (anchor) => (
        <>
          <div
            className={clsx(classes.list, {
              [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            style={{
              backgroundColor: isDarkMode && '#191b1f',
              paddingBottom: isDarkMode && mobileHeader && '250vw',
            }}
          >
            <List className="mobile-menu">
              <Link
                to="/vaults"
                className={`nav-link ${isDarkMode && 'nav-link-dark-mode'}`}
              >
                <img
                  src={isDarkMode ? whiteVaultIcon : vaultIcon}
                  alt="Vault"
                  className="vault-mobile"
                  style={{
                    height: 18,
                    width: 23,
                  }}
                />
                <span className="mobile-menu-tab-name">Vaults</span>
              </Link>
              <Link
                to="/pools"
                className={`nav-link ${isDarkMode && 'nav-link-dark-mode'}`}
              >
                <i className="fas fa-swimming-pool" />{' '}
                <span className="mobile-menu-tab-name">Pools</span>
              </Link>
              <Link
                to="/farms"
                className={`nav-link ${isDarkMode && 'nav-link-dark-mode'}`}
              >
                <i className="fas fa-tractor" />{' '}
                <span className="mobile-menu-tab-name">Farms</span>
              </Link>

              <Link
                to="/lend"
                className={`nav-link ${isDarkMode && 'nav-link-dark-mode'}`}
              >
                <i className="fas fa-university" />{' '}
                <span className="mobile-menu-tab-name">Lend</span>
              </Link>

              <Link
                to="/borrow"
                className={`nav-link ${isDarkMode && 'nav-link-dark-mode'}`}
              >
                <i className="fas fa-hand-holding-usd" />{' '}
                <span className="mobile-menu-tab-name">Borrow</span>
              </Link>

              <Link
                to="/longterm-bulls"
                className={`nav-link ${isDarkMode && 'nav-link-dark-mode'}`}
              >
                <img
                  src={isDarkMode ? whiteBull : bull}
                  alt="LongTerm Bulls"
                  className="vault-mobile"
                  style={{
                    height: 18,
                    width: 23,
                  }}
                />
                <span className="mobile-menu-tab-name">LongTerm Bulls</span>
              </Link>

              <Link
                to="/stats"
                className={`nav-link ${isDarkMode && 'nav-link-dark-mode'}`}
              >
                <i className="fas fa-chart-line" />{' '}
                <span className="mobile-menu-tab-name">Stats</span>
              </Link>

              <a
                href="https://snapshot.org/#/longtermgov.eth"
                target="_blank"
                rel="noreferrer"
                className={`nav-link ${isDarkMode && 'nav-link-dark-mode'}`}
              >
                <i className="fas fa-bullhorn" />{' '}
                <span className="mobile-menu-tab-name">Governance</span>
              </a>

              <Link
                to="/about"
                className={`nav-link ${isDarkMode && 'nav-link-dark-mode'}`}
              >
                <i className="fas fa-book-open" />{' '}
                <span className="mobile-menu-tab-name">About</span>
              </Link>
            </List>
            <ModeSwitcher />
            {signer.signer ? (
              <span className="navbar-address">
                <h4
                  style={{
                    marginRight: '15px',
                    marginLeft: '22px',
                    color: '#0066ff',
                    display: 'inline',
                    marginTop: '10px',
                  }}
                  className="navbar-address"
                >
                  <OverlayTrigger
                    key="bottom"
                    placement="bottom"
                    className="navbar-addresss"
                    overlay={
                      <Tooltip
                        id="tooltip-bottom"
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        Click to Copy
                      </Tooltip>
                    }
                  >
                    <a
                      href="/"
                      onClick={copyCodeToClipboard}
                      className="navbar-address"
                      style={{
                        color: '#0066ff',
                        marginTop: '30px',
                        textDecoration: 'underline',
                      }}
                    >
                      <span
                        style={{
                          textDecoration: 'underline',
                        }}
                      >
                        {makeShortAddress(address)}
                      </span>
                    </a>
                  </OverlayTrigger>
                </h4>
                <Link
                  to=""
                  onClick={(event) => {
                    event.preventDefault()
                    web3Modal.clearCachedProvider()
                    window.location.reload()
                  }}
                  style={{ marginRight: '25px', display: 'inline' }}
                >
                  <Icon
                    src={isDarkMode ? whiteLogoutIcon : logoutIcon}
                    alt={'Logout'}
                  />
                </Link>
              </span>
            ) : (
              <button
                className="btn regular-btn mr-4"
                type="button"
                style={{
                  fontWeight: 'bold',
                  height: '50px',
                  fontSize: '1.125em',
                  marginRight: '25px',
                  marginLeft: '22px',
                  marginTop: '20px',
                }}
                onClick={() => {
                  web3Modal.toggleModal()
                }}
              >
                Connect Wallet
              </button>
            )}
          </div>
        </>
      )

      const styles = {
        largeIcon: {
          width: 35,
          height: 35,
          position: 'relative',
          bottom: 3,
        },
      }

      return (
        <div>
          {['right'].map((anchor) => (
            <React.Fragment key={anchor}>
              <Button
                style={{ backgroundColor: isDarkMode ? '#191b1f' : '#fafbfd' }}
                onClick={toggleDrawer(anchor, true)}
              >
                <MenuIcon style={styles.largeIcon} className="menu-icon" />
              </Button>
              <SwipeableDrawer
                anchor={'right'}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                {list(anchor)}
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </div>
      )
    }

    const addLongToWallet = async () => {
      try {
        await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: '0x613D5D57Ce31A8EB3d9AB9750F79dF3b57cC9F56',
              symbol: 'LONG',
              decimals: 18,
              image:
                'https://raw.githubusercontent.com/longterm-finance/dashboard/master/src/assets/images/LONG_logo.png',
            },
          },
        })
      } catch (error) {
        console.error(error)
      }
    }

    const addAltsToWallet = async () => {
      try {
        await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: '0x63aD78D8007030130bB5b40E48B0FA2Cc700f294',
              symbol: 'ALTS',
              decimals: 18,
              image:
                'https://raw.githubusercontent.com/longterm-finance/dashboard/master/src/assets/images/ALTS_logo.png',
            },
          },
        })
      } catch (error) {
        console.error(error)
      }
    }

    return (
      <nav className={`navbar ${isDarkMode && 'navbar-dark-mode'}`}>
        {!mobileHeader ? (
          <React.Fragment>
            <div className="logo">
              <Link to="/">
                <img
                  className="logo-img"
                  src={transparentPNGLogo}
                  alt="LONG Logo"
                  width="177"
                  height="82"
                  style={{
                    marginLeft: '16px',
                  }}
                />
              </Link>
            </div>
            <div className="nav-links">
              <img
                onClick={addAltsToWallet}
                className="token-img mr-5"
                style={{ backgroundColor: '#ffffff' }}
                src={altsLogo}
                alt="ALTS logo"
                width="45"
                height="45"
              />

              <img
                onClick={addLongToWallet}
                className="token-img mr-5"
                style={{ backgroundColor: '#ffffff' }}
                src={longLogo}
                alt="LONG logo"
                width="45"
                height="45"
              />

              <span
                style={{
                  marginRight: '20px',
                }}
              >
                <ModeSwitcher />
              </span>
              {signer.signer ? (
                <>
                  <h4
                    style={{
                      marginRight: '15px',
                      marginLeft: '10px',
                      color: '#0066ff',
                    }}
                    className="short-address-navbar"
                  >
                    <OverlayTrigger
                      key="bottom"
                      placement="bottom"
                      overlay={
                        <Tooltip id="tooltip-bottom">Click to Copy</Tooltip>
                      }
                    >
                      <a
                        href="/"
                        onClick={copyCodeToClipboard}
                        className="address"
                        style={{ color: '#0066ff' }}
                      >
                        {makeShortAddress(address)}
                      </a>
                    </OverlayTrigger>
                  </h4>
                  <Link
                    to=""
                    onClick={(event) => {
                      event.preventDefault()
                      web3Modal.clearCachedProvider()
                      window.location.reload()
                    }}
                    style={{ marginRight: '25px' }}
                  >
                    <Icon
                      src={isDarkMode ? whiteLogoutIcon : logoutIcon}
                      alt={'Logout'}
                    />
                  </Link>
                </>
              ) : (
                <button
                  className="btn regular-btn mr-4"
                  type="button"
                  style={{
                    fontWeight: 'bold',
                    height: '50px',
                    fontSize: '1.125em',
                    marginRight: '25px',
                  }}
                  onClick={() => {
                    web3Modal.toggleModal()
                  }}
                >
                  Connect Wallet
                </button>
              )}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div
              className="logo"
              style={{
                position: 'relative',
                right: 25,
              }}
            >
              <Link to="/">
                <img
                  className="logo-img logo-img-header"
                  src={transparentPNGLogo}
                  alt="LONG Logo"
                  style={{
                    margin: 'auto',
                    position: 'relative',
                    right: '20',
                    marginLeft: '41px',
                  }}
                  width="177"
                  height="82"
                />
              </Link>
            </div>
            <SwipeableTemporaryDrawer />
          </React.Fragment>
        )}
      </nav>
    )
  }

  class SidebarItem extends React.Component {
    handleClick = () => {
      const { path, onItemClick } = this.props
      onItemClick(path)
    }

    render() {
      const { active, isLast } = this.props

      return (
        <React.Fragment>
          {this.props.path.includes('https://') ? (
            <a
              active={active}
              className={`pt-4 ${
                active ? 'sidebar-item-active' : 'sidebar-item'
              } ${this.props.css}`}
              href={this.props.path}
              target="_blank"
              rel="noreferrer"
              onClick={this.handleClick}
              style={{
                color: active ? '#fff' : '#b8b4b4',
                boxShadow: isLast && '0 4px 4px -2px #636262',
                borderBottom: !isLast && '1px solid #636262',
              }}
            >
              {(this.props.path === '/' || this.props.path === '/vaults') && (
                <img
                  src={active ? whiteVaultIcon : greyVaultIcon}
                  alt="Vault"
                  className="vault"
                  style={{
                    width: 22.5,
                    height: 22.5,
                  }}
                />
              )}
              <span
                className="sidebar-tab-name"
                style={{
                  position:
                    (this.props.path === '/' ||
                      this.props.path === '/vaults') &&
                    'relative',
                  top:
                    (this.props.path === '/' ||
                      this.props.path === '/vaults') &&
                    '1.5px',
                }}
              >
                {this.props.name}
              </span>
            </a>
          ) : (
            <Link
              active={active}
              className={`pt-4 ${
                active ? 'sidebar-item-active' : 'sidebar-item'
              } ${this.props.css}`}
              to={this.props.path}
              onClick={this.handleClick}
              style={{
                color: active ? '#fff' : '#b8b4b4',
                boxShadow: isLast && '0 4px 4px -2px #636262',
                borderBottom: !isLast && '1px solid #636262',
              }}
            >
              {(this.props.path === '/' || this.props.path === '/vaults') && (
                <img
                  src={active ? whiteVaultIcon : greyVaultIcon}
                  alt="Vault"
                  className="vault"
                  style={{
                    width: 22.5,
                    height: 22.5,
                  }}
                />
              )}
              {this.props.path === '/longterm-bulls' && (
                <img
                  src={active ? whiteBull : greyBull}
                  alt="LongTerm Bulls"
                  className="vault"
                  style={{
                    width: 22.5,
                    height: 22.5,
                  }}
                />
              )}
              <span
                className="sidebar-tab-name"
                style={{
                  position:
                    (this.props.path === '/' ||
                      this.props.path === '/vaults') &&
                    'relative',
                  top:
                    (this.props.path === '/' ||
                      this.props.path === '/vaults') &&
                    '1.5px',
                }}
              >
                {this.props.name}
              </span>
            </Link>
          )}
        </React.Fragment>
      )
    }
  }

  class Sidebar extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        activePath: props.location.pathname,
        items: [
          {
            path: '/vaults',
            name: 'Vaults',
            css: 'fas vault',
            key: Math.random(),
          },
          {
            path: '/pools',
            name: 'Pools',
            css: 'fas fa-swimming-pool',
            key: Math.random() + 1,
          },
          {
            path: '/farms',
            name: 'Farms',
            css: 'fas fa-tractor',
            key: Math.random() + 2,
          },
          {
            path: '/lend',
            name: 'Lend',
            css: 'fas fa-university',
            key: Math.random() + 3,
          },
          {
            path: '/borrow',
            name: 'Borrow',
            css: 'fas fa-hand-holding-usd',
            key: Math.random() + 4,
          },
          {
            path: '/longterm-bulls',
            name: 'LongTerm Bulls',
            css: 'fas vault',
            key: Math.random() + 5,
          },
          {
            path: '/stats',
            name: 'Stats',
            css: 'fas fa-chart-line',
            key: Math.random() + 6,
          },
          {
            path: 'https://snapshot.org/#/longtermgov.eth',
            name: 'Governance',
            css: 'fas fa-bullhorn',
            key: Math.random() + 7,
          },
          {
            path: '/about',
            name: 'About',
            css: 'fas fa-book-open',
            key: Math.random() + 8,
          },
        ],
      }
    }

    onItemClick = (path) => {
      this.setState({ activePath: path })
    }

    render() {
      const { items, activePath } = this.state
      return (
        <div className={isDarkMode ? 'sidebar sidebar-dark-mode' : 'sidebar'}>
          {items.map((item) => {
            return (
              <SidebarItem
                path={item.path}
                name={item.name}
                css={item.css}
                onItemClick={this.onItemClick}
                active={item.path === activePath}
                key={item.key}
                isLast={item.path === '/about'}
              />
            )
          })}
        </div>
      )
    }
  }

  const RouterSidebar = withRouter(Sidebar)

  return (
    <div className="dashboard-layout">
      <Navbar />
      {!mobileHeader && <RouterSidebar />}
    </div>
  )
}

export default DashboardLayout
