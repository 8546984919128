import React, { useContext } from 'react'
import './about.css'
import { useMediaQuery } from 'react-responsive'
import TwitterIcon from '@material-ui/icons/Twitter'
import TelegramIcon from '@material-ui/icons/Telegram'
import YouTubeIcon from '@material-ui/icons/YouTube'
import DocsIcon from '@material-ui/icons/MenuBook'
import RoadmapIcon from '@material-ui/icons/Timeline'
import LitepaperIcon from '@material-ui/icons/LibraryBooks'
import FAQIcon from '@material-ui/icons/Help'
import GitHubIcon from '@material-ui/icons/GitHub'
// import AuditReportIcon from '@material-ui/icons/BugReport'
import { ThemeContext } from '../../state/ThemeContext'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import Tooltip from 'react-bootstrap/esm/Tooltip'

const About = () => {
  const { isDarkMode } = useContext(ThemeContext)
  const isMobileView = useMediaQuery({ query: '(max-width: 510px)' })

  const WhatIsLongTerm = () => {
    return (
      <div
        className={`what-is-long-container ${
          isDarkMode ? 'learn-card-dark-mode' : 'learn-card'
        }`}
      >
        <h2 className="what-is-long-title mb-4">Introduction</h2>
        <p className="what-is-long-desc">
          <strong>
            LongTerm Finance enables you to simplify your crypto investing with
            ALTS,
          </strong>{' '}
          a token that tracks the entire crypto market excluding BTC and ETH.
          Easily diversify your portfolio and at the same time{' '}
          <strong>
            get the altcoin market's superior performance in one click.
          </strong>
        </p>
        <p className="what-is-long-desc">
          ALTS tokens are{' '}
          <strong>safely overcollateralized by at least 150%,</strong> using
          either MATIC, WETH, renBTC, DAI, MIM or UST as a collateral.
        </p>
        <p className="what-is-long-desc">
          {' '}
          LongTerm Finance is currently{' '}
          <strong>deployed on the Polygon (Matic) mainnet, </strong>
          with plans for the expansion to the other networks over time, such as
          Fantom, Avalanche and Ethereum.
        </p>{' '}
        <p className="what-is-long-desc">
          <strong>To learn more about LongTerm Finance,</strong> please visit{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://long-term.finance"
            className="doc-link"
            style={{
              color: '#0066ff',
              fontWeight: 'bold',
            }}
          >
            our official website
          </a>{' '}
          and also check out the available resources on this page.
        </p>
      </div>
    )
  }

  const Video = () => {
    return (
      <div
        className={`video-container ${
          isDarkMode ? 'learn-card-dark-mode' : 'learn-card'
        }`}
      >
        <h2 className="video-title mb-4">Tutorial: How to Mint & Burn ALTS</h2>
        <iframe
          src="https://www.youtube.com/embed/ttAWWvjGkfo"
          title="YouTube Video Player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="mr-4 video"
        />
      </div>
    )
  }

  const Instructions = () => {
    return (
      <div
        className={`instructions-container ${
          isDarkMode ? 'learn-card-dark-mode' : 'learn-card'
        }`}
      >
        <h2 className="instructions-title mb-4">Ideas & Tutorials</h2>
        <p className="instruction-desc">
          <strong>1.) Ideas: </strong>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.long-term.finance/protocol/how-to-use-alts"
            className="doc-link"
            style={{
              color: '#0066ff',
              fontWeight: 'bold',
            }}
          >
            How to Use the ALTS Token
          </a>
        </p>

        <p className="instruction-desc">
          <strong>2.) Tutorial: </strong>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.long-term.finance/protocol/tutorial-how-to-mint-and-burn-alts"
            className="doc-link"
            style={{
              color: '#0066ff',
              fontWeight: 'bold',
            }}
          >
            How to Mint & Burn ALTS Tokens
          </a>
        </p>

        <p className="instruction-desc">
          <strong>3.) Detailed Info: </strong>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.long-term.finance/protocol/deep-dive"
            className="doc-link"
            style={{
              color: '#0066ff',
              fontWeight: 'bold',
            }}
          >
            A Deep Dive into the LongTerm Finance Protocol
          </a>
        </p>
      </div>
    )
  }

  const Resources = () => {
    return (
      <div
        className={`resources-container ${
          isDarkMode ? 'learn-card-dark-mode' : 'learn-card'
        }`}
      >
        <h2 className="resources-title mb-4">Resources</h2>

        <div className="resources-blocks">
          <div className="resources-block-1">
            <p className="resources-link">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://docs.long-term.finance/"
                className="doc-link bold resources-link-inner"
              >
                <DocsIcon style={{ color: isDarkMode ? '#b7b7b7' : '#000' }} />
                <span className="ml-2">Docs</span>
              </a>
            </p>
            <p className="resources-link">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://docs.long-term.finance/protocol/litepaper"
                className="doc-link bold resources-link-inner"
              >
                <LitepaperIcon
                  style={{ color: isDarkMode ? '#b7b7b7' : '#000' }}
                />
                <span className="ml-2">Litepaper</span>
              </a>
            </p>
            <p className="resources-link">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://github.com/longterm-finance"
                className="doc-link bold resources-link-inner"
              >
                <GitHubIcon
                  style={{ color: isDarkMode ? '#b7b7b7' : '#000' }}
                />
                <span className="ml-2">Github</span>
              </a>
            </p>
          </div>
          <div className="resources-block-2">
            <p className="resources-link">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://docs.long-term.finance/protocol/future-plans"
                className="doc-link bold resources-link-inner"
              >
                <RoadmapIcon
                  style={{ color: isDarkMode ? '#b7b7b7' : '#000' }}
                />
                <span className="ml-2">Future Plans</span>
              </a>
            </p>
            <p className="resources-link">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://docs.long-term.finance/faq"
                className="doc-link bold resources-link-inner"
              >
                <FAQIcon style={{ color: isDarkMode ? '#b7b7b7' : '#000' }} />
                <span className="ml-2">FAQs</span>
              </a>
            </p>
            {/* @NOTE: ADD THIS LINK AFTER COMPLETING THE LONGTERM FINANCE AUDIT */}
            {/* <p className="resources-link">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://long-term.finance/LongTerm_Finance_Audit.pdf"
              className="doc-link bold resources-link-inner"
            >
              <AuditReportIcon
                style={{ color: isDarkMode ? '#b7b7b7' : '#000' }}
              />
              <span className="ml-2">Docs</span>
            </a>
          </p> */}
          </div>
        </div>
      </div>
    )
  }

  const copyAddress1 = (e) => {
    e.preventDefault()
    const el = document.createElement('input')
    el.value = '0x72A88e251024e8f23E1711E268C4eaC80213786C'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  const copyAddress2 = (e) => {
    e.preventDefault()
    const el = document.createElement('input')
    el.value = '32DfuNz32qVYFh2dgayfBLu32jttGdiSR3'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  const Support = () => {
    return (
      <div
        className={`support-container ${
          isDarkMode ? 'learn-card-dark-mode' : 'learn-card'
        }`}
      >
        <h2 className="support-title mb-4">Support</h2>
        <p className="support-desc">
          The fastest way to get support is to send an email to{' '}
          <a
            href="mailto:support@long-term.finance"
            className="doc-link bold"
            style={{ fontWeight: 'bold', color: '#0066ff' }}
          >
            support@long-term.finance
          </a>
        </p>
        <p className="support-desc">
          Email support is maintained by a{' '}
          <strong>group of volunteers from the community</strong> and is
          supported by tips:
        </p>
        <p className="support-desc">
          <strong>
            Ethereum Mainnet & Polygon Mainnet (ETH, MATIC & ERC20 Tokens):{' '}
          </strong>
          <span className="copy-address-span">
            <OverlayTrigger
              key="bottom"
              placement="bottom"
              overlay={
                <Tooltip
                  id="tooltip-bottom"
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  Click to Copy
                </Tooltip>
              }
            >
              <a
                href="/"
                onClick={copyAddress1}
                className="address"
                style={{ color: '#0066ff' }}
              >
                {isMobileView
                  ? // eslint-disable-next-line
                    '0x72A88e251024e8f23E1711E268' + ' ' + 'C4eaC80213786C'
                  : '0x72A88e251024e8f23E1711E268C4eaC80213786C'}
              </a>
            </OverlayTrigger>
          </span>{' '}
        </p>
        <p className="support-desc">
          <strong>Bitcoin (BTC): </strong>{' '}
          <span className="copy-address-span">
            <OverlayTrigger
              key="bottom"
              placement="bottom"
              overlay={
                <Tooltip
                  id="tooltip-bottom"
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  Click to Copy
                </Tooltip>
              }
            >
              <a
                href="/"
                onClick={copyAddress2}
                className="address"
                style={{ color: '#0066ff' }}
              >
                {isMobileView
                  ? // eslint-disable-next-line
                    '32DfuNz32qVYFh2dgayfB' + ' ' + 'Lu32jttGdiSR3'
                  : '32DfuNz32qVYFh2dgayfBLu32jttGdiSR3'}
              </a>
            </OverlayTrigger>
          </span>{' '}
        </p>
        <p className="support-desc">
          Also, you can ask our community for the support in our{' '}
          <a
            href="https://t.me/joinchat/_-xv_PVkLTJlNGJk"
            target="_blank"
            rel="noreferrer"
            className="doc-link community-link-inner"
          >
            Telegram Group.{' '}
          </a>
        </p>
      </div>
    )
  }

  const MediumIcon = () => {
    return (
      <svg
        width="22.5"
        height="22.5"
        xmlns="http://www.w3.org/2000/svg"
        fillRule="evenodd"
        clipRule="evenodd"
        fill={isDarkMode ? '#b7b7b7' : '#000'}
      >
        <path d="M2.846 6.887c.03-.295-.083-.586-.303-.784l-2.24-2.7v-.403h6.958l5.378 11.795 4.728-11.795h6.633v.403l-1.916 1.837c-.165.126-.247.333-.213.538v13.498c-.034.204.048.411.213.537l1.871 1.837v.403h-9.412v-.403l1.939-1.882c.19-.19.19-.246.19-.537v-10.91l-5.389 13.688h-.728l-6.275-13.688v9.174c-.052.385.076.774.347 1.052l2.521 3.058v.404h-7.148v-.404l2.521-3.058c.27-.279.39-.67.325-1.052v-10.608z" />
      </svg>
    )
  }

  const Community = () => {
    return (
      <div
        className={`community-container ${
          isDarkMode ? 'learn-card-dark-mode' : 'learn-card'
        }`}
      >
        <h2 className="community-title mb-4">Join the Community</h2>
        <div className="community-blocks">
          <div className="community-block-1">
            <p className="community-link">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/LongTermFi"
                className="doc-link bold community-link-inner"
              >
                <TwitterIcon
                  style={{
                    /* color: isDarkMode ? '#b7b7b7' : '#000'  */
                    color: '#1CB7EB',
                  }}
                />
                <span className="ml-2">Twitter</span>
              </a>
            </p>
            <p className="community-link">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://t.me/joinchat/_-xv_PVkLTJlNGJk"
                className="doc-link bold community-link-inner"
              >
                <TelegramIcon
                  style={{
                    /* color: isDarkMode ? '#b7b7b7' : '#000'  */
                    color: '#139BD0',
                  }}
                />
                <span className="ml-2">Telegram</span>
              </a>
            </p>
          </div>
          <div className="community-block-2">
            <p className="community-link">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://blog.long-term.finance"
                className="doc-link bold community-link-inner"
              >
                <MediumIcon
                  style={{ color: isDarkMode ? '#b7b7b7' : '#000' }}
                />
                <span className="ml-2">Medium</span>
              </a>
            </p>
            <p className="community-link">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/channel/UCkAIsh6zWrrc9w4c6zpYfrQ"
                className="doc-link bold community-link-inner"
              >
                <YouTubeIcon
                  style={{
                    /* color: isDarkMode ? '#b7b7b7' : '#000' */
                    color: '#FF0000',
                  }}
                />
                <span className="ml-2">YouTube</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={`${
        !isDarkMode ? 'learn-container' : 'learn-container-dark-mode'
      } mb-5`}
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <h1
        className="text-center bold mb-5 mt-5 learn-title"
        style={{ fontSize: '3.15rem', color: isDarkMode && '#fff' }}
      >
        About LongTerm Finance
      </h1>

      <div className="learn-grid">
        <WhatIsLongTerm />
        <Video />
        <Instructions />
        <Resources />
        <Support />
        <Community />
      </div>
      <br />
      <br />
      <br />
    </div>
  )
}

export default About
