export const RPC_URL = 'https://polygon-rpc.com'
export const CHAIN_ID = 137
export const CHAIN_ID_HEX = '0x89'
export const NETWORK_NAME = 'matic'
export const MATIC = '0x0000000000000000000000000000000000001010'
export const WMATIC = '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'
export const STMATIC = ''
export const WETH = '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619'
export const STETH = '0x4C6B65fe93fc9DaF413498b88195FAfFF36dD960'
export const RENBTC = '0xdbf31df14b66535af65aac99c32e9ea844e14501'
export const DAI = '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063'
export const MIM = '0x49a0400587a7f65072c87c4910449fdcc5c47242'
export const UST = '0x692597b009d13c4049a947cab2239b7d6517875f'
export const LINK = '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39'
export const SUSHI = '0x0b3f868e0be5597d5db7feb59e1cadbb0fdda50a'
export const XSUSHI = '0x6811079e3c63ed96eb005384d7e7ec8810e3d521'
export const QUICK = '0x831753dd7087cac61ab5644b308642cc1c33dc13'
export const DQUICK = '0xf28164a485b0b2c90639e47b0f377b4a438a16b1'
export const AAVE = '0xd6df932a45c0f255f85145f286ea0b292b21c90b'
export const CRV = '0x172370d5cd63279efa6d502dab29171933a610af'
export const UNI = '0xb33eaad8d922b1083446dc23f610c2567fb5180f'
export const BCT = '0x2f800db0fdb5223b3c3f354886d907a671414a7f'
export const ALTS_POOL = '0x8e7d59604ce54ef0065b347bf136c31f34d9514a'
export const LONG_POOL = '0x0497c73ecd948c8a08fff2674e71cfdf3f34074f'
export const EXPLORER_URL = 'https://polygonscan.com'
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
export const BURN_ADDRESS = '0x000000000000000000000000000000000000dEaD'
export const NFT_MAX_SUPPLY = 2048
