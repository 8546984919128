import React, { useContext, useState, useEffect } from 'react'
import './pool.css'
import { Link } from 'react-router-dom'
import long from '../../assets/images/LONG_logo.png'
import alts from '../../assets/images/ALTS_logo.png'
import eth from '../../assets/images/eth.png'
import { ThemeContext } from '../../state/ThemeContext'
import { ethers } from 'ethers'
import NumberFormat from 'react-number-format'
import longtermJson from '../../contracts/longterm.json'
import uniV2Pair from '../../contracts/UniswapV2Pair.json'
import { RPC_URL, LONG_POOL, ALTS_POOL } from '../../utils/constants'

const Pool = () => {
  const { isDarkMode } = useContext(ThemeContext)

  const [isLoading, setIsLoading] = useState(true)
  const [poolWarningOpen, setPoolWarningOpen] = useState(true)
  const [altsPoolData, setAltsPoolData] = useState('0.00')
  const [longPoolData, setLongPoolData] = useState('0.00')

  const handlePoolWarning = () => setPoolWarningOpen(false)

  useEffect(() => {
    const loadData = async () => {
      const provider = new ethers.providers.JsonRpcProvider(RPC_URL)
      const contracts = longtermJson[137].matic.contracts

      // Set required contracts
      const LONGLP = new ethers.Contract(LONG_POOL, uniV2Pair.abi, provider)

      const ALTSLP = new ethers.Contract(ALTS_POOL, uniV2Pair.abi, provider)

      const ETHOracle = new ethers.Contract(
        contracts.ETHOracle.address,
        contracts.ETHOracle.abi,
        provider,
      )

      const ALTSOracle = new ethers.Contract(
        contracts.ALTSOracle.address,
        contracts.ALTSOracle.abi,
        provider,
      )

      // Get oracle prices
      const ethUSDRes = await ETHOracle.getLatestAnswer()
      const ethUSD = ethers.utils.formatUnits(ethUSDRes.toString(), 8)

      const altsUSDRes = await ALTSOracle.getLatestAnswer()
      const altsUSD = ethers.utils.formatUnits(altsUSDRes.toString(), 8)

      // LONG/ETH & ALTS/ETH Pairs Liquidity Data
      const longLPData = await LONGLP.getReserves()
      const longLiquidity = ethers.utils.formatEther(longLPData[0].toString())
      const ethLiquidity = ethers.utils.formatEther(longLPData[1].toString())
      const longUSD =
        ethUSD /
        parseFloat(
          ethers.utils.formatEther(
            ethers.utils.parseEther('1').mul(longLPData[0]).div(longLPData[1]),
          ),
        )
      const longPairLiquidity =
        parseFloat(longLiquidity) * parseFloat(longUSD) +
        parseFloat(ethLiquidity) * parseFloat(ethUSD)

      const altsLPData = await ALTSLP.getReserves()
      const altsLiquidity = ethers.utils.formatEther(altsLPData[0].toString())
      const ethLiquidity2 = ethers.utils.formatEther(altsLPData[1].toString())
      const altsPairLiquidity =
        parseFloat(altsLiquidity) * parseFloat(altsUSD) +
        parseFloat(ethLiquidity2) * parseFloat(ethUSD)

      setAltsPoolData(altsPairLiquidity)
      setLongPoolData(longPairLiquidity)

      setIsLoading(false)
    }

    loadData()
  }, [altsPoolData, longPoolData])

  const PoolCard = ({
    icon1,
    icon2,
    tradingPair,
    dex,
    dexLink,
    liquidity,
    analyticsLink,
    tradeLink,
    poolLink,
  }) => {
    return (
      <div
        className={`${
          !isDarkMode
            ? 'trade-card-container-new'
            : 'trade-card-container-dark-mode-new'
        }`}
      >
        <div className="trade-card-icons">
          <img
            src={icon1}
            width="50"
            height="50"
            alt={tradingPair && tradingPair.split('/')[0]}
            className="trade-icon-1"
            style={{
              backgroundColor: '#ffffff',
            }}
          />
          <img
            src={icon2}
            width="50"
            height="50"
            alt={tradingPair && tradingPair.split('/')[1]}
            className="trade-icon-2"
            style={{
              border: 'none',
            }}
          />
        </div>
        <h3 className="trade-card-title">
          <a
            target="_blank"
            rel="noreferrer"
            className="trade-card-analytics-link farm-link"
            style={{ fontWeight: 'bold', color: isDarkMode ? '#fff' : '#000' }}
            href={`${dexLink}`}
          >
            {tradingPair + ' Pool'}
          </a>
        </h3>
        <br />
        <p className="trade-card-dex">
          <strong>DEX: </strong>
          <a
            target="_blank"
            rel="noreferrer"
            className="trade-card-analytics-link farm-link"
            style={{ fontWeight: 'bold', color: '#0066ff' }}
            href={dexLink}
          >
            {dex}
          </a>
        </p>
        <p className="trade-card-dex">
          <strong>Status: </strong> Active
        </p>
        <p className="trade-card-trading-volume">
          <strong>Liquidity:</strong> ${liquidity}
        </p>
        <br />
        <div className="trading-buttons">
          <button className="btn regular-btn border-rad-05 mr-3" type="button">
            <a
              target="_blank"
              rel="noreferrer"
              href={analyticsLink}
              className="trade-card-analytics-link"
              style={{ color: 'white', fontWeight: 'bold' }}
            >
              Analytics
            </a>
          </button>
          <button className="btn regular-btn border-rad-05 mr-3" type="button">
            <a
              target="_blank"
              rel="noreferrer"
              href={tradeLink}
              className="trade-card-analytics-link"
              style={{ color: 'white', fontWeight: 'bold' }}
            >
              Trade
            </a>
          </button>
          <button className="btn regular-btn border-rad-05" type="button">
            <a
              target="_blank"
              rel="noreferrer"
              href={poolLink}
              className="trade-card-trade-link"
              style={{ color: 'white', fontWeight: 'bold' }}
            >
              Pool
            </a>
          </button>
        </div>
      </div>
    )
  }

  return (
    <div
      className={`pool-container ${
        !isDarkMode ? 'trade-container' : 'trade-container-dark-mode'
      } mb-5`}
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <h1
        className="text-center bold mb-5 mt-5"
        style={{ fontSize: '2.75rem' }}
      >
        Enabled Pools
      </h1>

      <p
        className={`text-mute text-center ml-5 mr-5 mb-5 ${
          isDarkMode ? 'early-adopter-note-dark-mode' : 'early-adopter-note'
        }`}
      >
        As a popular financial product for long term investors, ALTS represents{' '}
        <strong>a very lucrative opportunity for liquidity providers</strong> to
        sustainably earn above average yields{' '}
        <strong>with the minimal exposure to the impermanent loss.</strong>
        <br />
        Also, you can further boost yields by{' '}
        <strong>
          depositing your LP tokens into{' '}
          <Link to="/farms" style={{ color: '#0066ff' }} className="doc-link">
            our incentivized farms
          </Link>
          ,
        </strong>{' '}
        where you can earn additional rewards in the form of our native
        governance token (LONG token).
      </p>

      <br />

      {poolWarningOpen && (
        <div
          className={`${
            isDarkMode ? 'c-ratio-alert-dark-mode' : 'c-ratio-alert'
          }`}
          style={{
            fontSize: '1.1em',
          }}
        >
          <span>
            <strong>⚠️ Note:</strong> When adding liquidity to the ALTS / WETH
            pool, <strong>please use WETH from your wallet</strong> instead of
            swapping half of your newly minted ALTS tokens for WETH.
          </span>
          <span className="dismiss-alert" onClick={handlePoolWarning}>
            X
          </span>
        </div>
      )}

      <span
        className="text-center"
        style={{
          display: 'block',
          fontSize: '2rem',
          fontWeight: 'bold',
        }}
      >
        {isLoading && (
          <>
            (Loading Pools Data...)
            <br />
            <br />
            <br />
          </>
        )}
      </span>

      <div className="trade-grid">
        <PoolCard
          icon1={alts}
          icon2={eth}
          tradingPair="ALTS / WETH"
          dex="SushiSwap"
          dexLink="https://app.sushi.com/add/0x63aD78D8007030130bB5b40E48B0FA2Cc700f294/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619"
          liquidity={
            <NumberFormat
              className="number"
              value={!isLoading ? altsPoolData : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          analyticsLink={`https://analytics-polygon.sushi.com/pairs/0x8e7d59604ce54ef0065b347bf136c31f34d9514a`}
          tradeLink={`https://app.sushi.com/swap?inputCurrency=0x63aD78D8007030130bB5b40E48B0FA2Cc700f294&outputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619`}
          poolLink={`https://app.sushi.com/add/0x63aD78D8007030130bB5b40E48B0FA2Cc700f294/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619`}
        />
        <PoolCard
          icon1={long}
          icon2={eth}
          tradingPair="LONG / WETH"
          dex="SushiSwap"
          dexLink="https://app.sushi.com/add/0x613D5D57Ce31A8EB3d9AB9750F79dF3b57cC9F56/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619"
          liquidity={
            <NumberFormat
              className="number"
              value={!isLoading ? longPoolData : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          analyticsLink={`https://analytics-polygon.sushi.com/pairs/0x0497c73ecd948c8a08fff2674e71cfdf3f34074f`}
          tradeLink={`https://app.sushi.com/swap?inputCurrency=0x613D5D57Ce31A8EB3d9AB9750F79dF3b57cC9F56&outputCurrency=0x7ceb23fd6bc0add59e62ac25578270cff1b9f619`}
          poolLink={`https://app.sushi.com/add/0x613D5D57Ce31A8EB3d9AB9750F79dF3b57cC9F56/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619`}
        />
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  )
}

export default Pool
