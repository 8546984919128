import React, { useContext } from 'react'
import './lend.css'
import { ThemeContext } from '../../state/ThemeContext'
import dai from '../../assets/images/dai.png'
import { Link } from 'react-router-dom'

const Lend = () => {
  const { isDarkMode } = useContext(ThemeContext)

  const approveFn = () => {
    alert('Coming Soon!')
  }

  return (
    <div
      className={`${
        isDarkMode ? 'trade-container-dark-mode' : 'trade-container'
      }`}
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="create-vault-container">
        <br />
        <br />

        <img
          src={dai}
          alt="DAI"
          width="70"
          height="70"
          style={{
            marginTop: '12px',
          }}
        />
        <h1
          className="text-center bold mb-4 mt-4"
          style={{ fontSize: '2.75rem' }}
        >
          Lend DAI (Coming Soon!)
        </h1>

        <h2 className="text-center bold mb-4">
          Deposit DAI to start earning interest in a safe and predictable way
        </h2>

        <p
          className={`text-mute text-center ml-5 mr-5 mb-5 ${
            isDarkMode ? 'early-adopter-note-dark-mode' : 'early-adopter-note'
          }`}
        >
          Deposit APY varies based on the current utilization rate, and{' '}
          <strong>can be up to 5%,</strong> which is the fixed interest rate
          that gets paid by the{' '}
          <Link
            to="/borrow"
            style={{ color: '#0066ff', fontWeight: 'bold' }}
            className="doc-link"
          >
            borrowers
          </Link>{' '}
          on the LongTerm Finance protocol.{' '}
          <strong>
            Our long-term target for the sustainable deposit APY is 3% or more.
          </strong>{' '}
          To learn more, please check out{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://docs.long-term.finance/protocol/lend-and-borrow"
            style={{ color: '#0066ff' }}
            className="doc-link"
          >
            <strong>our official documentation.</strong>
          </a>
        </p>

        <button
          className="btn regular-btn create-vault-btn"
          type="button"
          onClick={approveFn}
        >
          Approve DAI
        </button>
      </div>
    </div>
  )
}

export default Lend
