import { ethers } from 'ethers'
import React from 'react'
import { toast } from 'react-toastify'
import './utils.css'

export const makeShortAddress = (address) => {
  const shortAddress = `${address.substr(0, 6).toString()}...${address
    .substr(address.length - 4, address.length)
    .toString()}`
  return shortAddress
}

export const isValidAddress = async (address) => {
  try {
    const currentAddress = ethers.utils.getAddress(address)
    return currentAddress
  } catch (error) {
    console.error(error)
    return null
  }
}

export const parseUint = (value) => {
  if (parseInt(value) < 0) {
    return '0'
  }
  return value
}

export const toUSD = (amount, price) => parseFloat(amount) * parseFloat(price)

export const tsToDateString = (ts) => {
  const dt = new Date(ts * 1000)
  return dt.toLocaleDateString()
}

export const sendNotification = (
  title,
  body,
  duration = 3000,
  fn = () => {},
  delay = 0,
  className = '',
) => {
  const toastConstant = (
    <div className="">
      <h3>{title}</h3>
      <p>{body}</p>
    </div>
  )

  toast(toastConstant, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: duration,
    hideProgressBar: true,
    delay,
    className,
    onClose: () => {
      fn()
    },
  })
}

export const errorNotification = async (body) => {
  const title = '❌ Error!'
  sendNotification(title, body, 3000, () => {}, 0, 'error')
}

export const notifyUser = async (tx, fn = () => {}) => {
  try {
    let notificationTitle = '⏰ Transaction Sent!'
    let notificationBody = 'Please wait for the transaction confirmation.'
    sendNotification(notificationTitle, notificationBody, false)

    await tx.wait(1)

    toast.dismiss()

    notificationTitle = '✔️ Transaction Confirmed!'
    notificationBody = 'Transaction was successful.'
    sendNotification(
      notificationTitle,
      notificationBody,
      3000,
      fn,
      1000,
      'success',
    )

    fn()
  } catch (error) {
    console.error(error)
  }
}

export const getRatio = async (
  collateral,
  collateralPrice,
  debt,
  altsPrice,
) => {
  const c = parseFloat(collateral)
  const cp = parseFloat(collateralPrice)
  const d = parseFloat(debt)
  const tp = parseFloat(altsPrice)
  if (d === 0 || tp === 0) return 0
  const ratio = (c * cp * 100) / (d * tp)
  return ratio
}

export const getSafeMint = async (
  ratio,
  collateral,
  collateralPrice,
  altsPrice,
  debt,
) => {
  const r = parseFloat(ratio) + 50
  const c = parseFloat(collateral)
  const cp = parseFloat(collateralPrice)
  const tp = parseFloat(altsPrice)
  const d = parseFloat(debt)
  if (r === 0 || tp === 0) return 0
  const safeMint = (c * cp * 100) / (r * tp)

  const result = safeMint - d
  if (result < 0) {
    return 0
  }
  return result
}

export const getSafeRemoveCollateral = async (
  ratio,
  collateral,
  collateralPrice,
  altsPrice,
  debt,
) => {
  const r = parseFloat(ratio) + 50
  const c = parseFloat(collateral)
  const cp = parseFloat(collateralPrice)
  const tp = parseFloat(altsPrice)
  const d = parseFloat(debt)
  if (cp === 0) return 0
  const n = (r * d * tp) / (cp * 100)

  const result = c - n
  if (result < 0) {
    return 0
  }
  return result
}

export const getPriceInUSDFromPair = (reserves0, reservesETH, ethPrice) => {
  const one = ethers.utils.parseEther('1')
  const amt = parseFloat(
    ethers.utils.formatEther(one.mul(reserves0).div(reservesETH)),
  )
  return ethPrice / amt
}
