import React from 'react'

export const VAULTS_DEFAULT_VALUE = {
  setCurrentETHVault: () => {},
  setCurrentBTCVault: () => {},
  setCurrentMIMVault: () => {},

  setCurrentETHVaultRead: () => {},
  setCurrentBTCVaultRead: () => {},
  setCurrentMIMVaultRead: () => {},
}

const vaultsContext = React.createContext(VAULTS_DEFAULT_VALUE)

export default vaultsContext
