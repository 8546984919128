import { useState, useCallback } from 'react'

export const useVaults = () => {
  const [ethVault, setETHVault] = useState()
  const [btcVault, setBTCVault] = useState()
  const [mimVault, setMIMVault] = useState()

  const [ethVaultRead, setETHVaultRead] = useState()
  const [btcVaultRead, setBTCVaultRead] = useState()
  const [mimVaultRead, setMIMVaultRead] = useState()

  const setCurrentETHVault = useCallback((currentETHVault) => {
    setETHVault(currentETHVault)
  }, [])

  const setCurrentBTCVault = useCallback((currentBTCVault) => {
    setBTCVault(currentBTCVault)
  }, [])

  const setCurrentMIMVault = useCallback((currentMIMVault) => {
    setMIMVault(currentMIMVault)
  }, [])

  const setCurrentETHVaultRead = useCallback((currentETHVaultRead) => {
    setETHVaultRead(currentETHVaultRead)
  }, [])

  const setCurrentBTCVaultRead = useCallback((currentBTCVaultRead) => {
    setBTCVaultRead(currentBTCVaultRead)
  }, [])

  const setCurrentMIMVaultRead = useCallback((currentMIMVaultRead) => {
    setMIMVaultRead(currentMIMVaultRead)
  }, [])

  return {
    ethVault,
    setCurrentETHVault,
    ethVaultRead,
    setCurrentETHVaultRead,

    btcVault,
    setCurrentBTCVault,
    btcVaultRead,
    setCurrentBTCVaultRead,

    mimVault,
    setCurrentMIMVault,
    mimVaultRead,
    setCurrentMIMVaultRead,
  }
}
