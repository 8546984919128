import React, { useContext } from 'react'
import { ThemeContext } from '../../state/ThemeContext'
import { Link } from 'react-router-dom'

const AddPolygon = () => {
  const { isDarkMode } = useContext(ThemeContext)

  const addPolygon = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0x89',
            chainName: 'Polygon (Matic) Mainnet',
            nativeCurrency: {
              name: 'Polygon',
              symbol: 'MATIC',
              decimals: 18,
            },
            rpcUrls: ['https://polygon-rpc.com/'],
            blockExplorerUrls: ['https://polygonscan.com/'],
            iconUrls: [
              'https://assets.coingecko.com/coins/images/4713/small/matic-token-icon.png?1624446912',
            ],
          },
        ],
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div
      className={`${
        isDarkMode ? 'trade-container-dark-mode' : 'trade-container'
      }`}
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <button
        className="btn regular-btn bold border-rad-05 back-to-vaults-btn"
        type="button"
      >
        <Link
          to="/vaults"
          className="trade-card-analytics-link"
          style={{
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.175rem',
          }}
        >
          ↩ Back to Vaults
        </Link>
      </button>

      <div className="create-vault-container">
        <h1
          className="text-center bold mb-5 mt-4"
          style={{ fontSize: '2.75rem' }}
        >
          Add Polygon Network
        </h1>

        <br />

        <p className="text-center bold create-vault-subtitle">
          Please connect your wallet and click on "Add Polygon" to switch to the
          Polygon (Matic) network. This will make it possible for you to create
          a vault and start minting ALTS tokens.
        </p>
        <button
          className="btn regular-btn create-vault-btn"
          type="button"
          onClick={addPolygon}
        >
          Add Polygon
        </button>
      </div>
    </div>
  )
}

export default AddPolygon
