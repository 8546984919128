import React from 'react'

export const ORACLES_DEFAULT_VALUE = {
  setCurrentETHOracle: () => {},
  setCurrentBTCOracle: () => {},
  setCurrentMIMOracle: () => {},
  setCurrentALTSOracle: () => {},

  setCurrentETHOracleRead: () => {},
  setCurrentBTCOracleRead: () => {},
  setCurrentMIMOracleRead: () => {},
  setCurrentALTSOracleRead: () => {},
}

const oraclesContext = React.createContext(ORACLES_DEFAULT_VALUE)

export default oraclesContext
