import React, { Suspense, useState, useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import { ethers } from 'ethers'
import Spinner from './components/Layout/Spinner'
import ErrorBoundary from './components/Layout/ErrorBoundary'
import './App.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'semantic-ui-css/semantic.min.css'
import ThemeProvider from './state/ThemeContext'
import GovernanceContext from './state/GovernanceContext'
import OraclesContext from './state/OraclesContext'
import RewardsContext from './state/RewardsContext'
import SignerContext from './state/SignerContext'
import TokensContext from './state/TokensContext'
import VaultsContext from './state/VaultsContext'
import { Web3ModalContext } from './state/Web3ModalContext'
import { useGovernance } from './hooks/useGovernance'
import { useOracles } from './hooks/useOracles'
import { useRewards } from './hooks/useRewards'
import { useSigner } from './hooks/useSigner'
import { useTokens } from './hooks/useTokens'
import { useVaults } from './hooks/useVaults'
import DashboardLayout from './components/Dashboard/DashboardLayout'
import Vaults from './components/Vault/Vaults'
import MATICVault from './components/Vault/MATICVault'
import ETHVault from './components/Vault/ETHVault'
import BTCVault from './components/Vault/BTCVault'
import DAIVault from './components/Vault/DAIVault'
import MIMVault from './components/Vault/MIMVault'
import USTVault from './components/Vault/USTVault'
import Pool from './components/Pool/Pool'
import Farm from './components/Farm/Farm'
import Lend from './components/Lend/Lend'
import Borrow from './components/Borrow/Borrow'
import BorrowALTS from './components/Borrow/BorrowALTS'
import BorrowLONG from './components/Borrow/BorrowLONG'
import BorrowALTSWETH from './components/Borrow/BorrowALTSWETH'
import BorrowLONGWETH from './components/Borrow/BorrowLONGWETH'
import LongTermBulls from './components/LongTermBulls/LongTermBulls'
import Stats from './components/Stats/Stats'
import About from './components/About/About'
import NotFound from './components/Layout/NotFound'
import AddPolygon from './components/Layout/AddPolygon'

const Toastify = () => (
  <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
)

const App = () => {
  const signer = useSigner()
  const web3Modal = useContext(Web3ModalContext)
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(true)
  const vaults = useVaults()
  const tokens = useTokens()
  const oracles = useOracles()
  const governance = useGovernance()
  const rewards = useRewards()

  web3Modal.on('connect', async (networkProvider) => {
    setIsLoading(true)

    const currentProvider = new ethers.providers.Web3Provider(networkProvider)
    await currentProvider.getNetwork()

    const currentSigner = currentProvider.getSigner()
    signer.setCurrentSigner(currentSigner)

    setIsLoading(false)
  })

  window.ethereum.on('chainChanged', (_chainId) => window.location.reload())

  return (
    <SignerContext.Provider value={signer}>
      <TokensContext.Provider value={tokens}>
        <OraclesContext.Provider value={oracles}>
          <VaultsContext.Provider value={vaults}>
            <GovernanceContext.Provider value={governance}>
              <RewardsContext.Provider value={rewards}>
                <ThemeProvider>
                  <ErrorBoundary>
                    <Suspense fallback={<Spinner />}>
                      <DashboardLayout />
                      <Toastify />
                      <Switch>
                        {' '}
                        <Route exact path="/" component={Vaults} />
                        <Route exact path="/vaults" component={Vaults} />
                        <Route
                          exact
                          path="/vaults/MATIC"
                          component={MATICVault}
                        />
                        <Route exact path="/vaults/WETH" component={ETHVault} />
                        <Route
                          exact
                          path="/vaults/renBTC"
                          component={BTCVault}
                        />
                        <Route exact path="/vaults/DAI" component={DAIVault} />
                        <Route exact path="/vaults/MIM" component={MIMVault} />
                        <Route exact path="/vaults/UST" component={USTVault} />
                        <Route exact path="/pools" component={Pool} />
                        <Route exact path="/farms" component={Farm} />
                        <Route exact path="/lend" component={Lend} />
                        <Route exact path="/borrow" component={Borrow} />
                        <Route
                          exact
                          path="/borrow/ALTS"
                          component={BorrowALTS}
                        />
                        <Route
                          exact
                          path="/borrow/ALTS-WETH"
                          component={BorrowALTSWETH}
                        />
                        <Route
                          exact
                          path="/borrow/LONG"
                          component={BorrowLONG}
                        />
                        <Route
                          exact
                          path="/borrow/LONG-WETH"
                          component={BorrowLONGWETH}
                        />
                        <Route exact path="/longterm-bulls" component={LongTermBulls} />
                        <Route exact path="/stats" component={Stats} />
                        <Route exact path="/about" component={About} />
                        <Route
                          exact
                          path="/add-polygon"
                          component={AddPolygon}
                        />
                        <Route component={NotFound} />
                      </Switch>
                    </Suspense>
                  </ErrorBoundary>
                </ThemeProvider>
              </RewardsContext.Provider>
            </GovernanceContext.Provider>
          </VaultsContext.Provider>
        </OraclesContext.Provider>
      </TokensContext.Provider>
    </SignerContext.Provider>
  )
}

export default App
