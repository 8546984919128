import React from 'react'
import Web3Modal from 'web3modal'

let network = 'matic'

switch (process.env.CHAIN_ID) {
  case 137:
    network = 'matic'
    break
  default:
    break
}

const providerOptions = {
  injected: {
    display: {
      logo: '../assets/images/metamask.svg',
      name: 'MetaMask',
      description: 'Connect with the MetaMask wallet in your browser',
    },
    package: null, // true ??
    options: {
      rpc: {
        137: process.env.RPC_URL,
      },
    },
  },
}

const web3Modal = new Web3Modal({
  network,
  cacheProvider: true, // optional
  providerOptions, // required
  disableInjectedProvider: false,
  theme: {
    background: '#1d1d3c',
    main: 'white',
    secondary: '#f5f5f5',
    border: '#e440f2',
    hover: 'rgba(241, 36, 255, 0.0)',
  },
})

export const Web3ModalContext = React.createContext(web3Modal)
