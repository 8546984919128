import { useState, useCallback } from 'react'

export const useTokens = () => {
  const [ethToken, setETHToken] = useState()
  const [btcToken, setBTCToken] = useState()
  const [mimToken, setMIMToken] = useState()
  const [altsToken, setALTSToken] = useState()
  const [longToken, setLongToken] = useState()

  const [altsPoolToken, setALTSPoolToken] = useState() // ALTS/ETH LP
  const [longPoolToken, setLONGPoolToken] = useState() // LONG/ETH LP

  const [ethTokenRead, setETHTokenRead] = useState()
  const [btcTokenRead, setBTCTokenRead] = useState()
  const [mimTokenRead, setMIMTokenRead] = useState()
  const [altsTokenRead, setALTSTokenRead] = useState()
  const [longTokenRead, setLongTokenRead] = useState()

  const [altsPoolTokenRead, setALTSPoolTokenRead] = useState() // ALTS/ETH LP
  const [longPoolTokenRead, setLONGPoolTokenRead] = useState() // LONG/ETH LP

  const setCurrentETHToken = useCallback((currentETHToken) => {
    setETHToken(currentETHToken)
  }, [])
  const setCurrentBTCToken = useCallback((currentBTCToken) => {
    setBTCToken(currentBTCToken)
  }, [])
  const setCurrentMIMToken = useCallback((currentMIMToken) => {
    setMIMToken(currentMIMToken)
  }, [])
  const setCurrentALTSToken = useCallback((currentALTSToken) => {
    setALTSToken(currentALTSToken)
  }, [])
  const setCurrentLongToken = useCallback((currentLong) => {
    setLongToken(currentLong)
  }, [])

  const setCurrentALTSPoolToken = useCallback((currentALTSPoolToken) => {
    setALTSPoolToken(currentALTSPoolToken)
  }, [])
  const setCurrentLONGPoolToken = useCallback((currentLONGPoolToken) => {
    setLONGPoolToken(currentLONGPoolToken)
  }, [])

  const setCurrentETHTokenRead = useCallback((currentETHTokenRead) => {
    setETHTokenRead(currentETHTokenRead)
  }, [])
  const setCurrentBTCTokenRead = useCallback((currentBTCTokenRead) => {
    setBTCTokenRead(currentBTCTokenRead)
  }, [])
  const setCurrentMIMTokenRead = useCallback((currentMIMTokenRead) => {
    setMIMTokenRead(currentMIMTokenRead)
  }, [])
  const setCurrentALTSTokenRead = useCallback((currentALTSTokenRead) => {
    setALTSTokenRead(currentALTSTokenRead)
  }, [])
  const setCurrentLongTokenRead = useCallback((currentLongRead) => {
    setLongTokenRead(currentLongRead)
  }, [])

  const setCurrentALTSPoolTokenRead = useCallback(
    (currentALTSPoolTokenRead) => {
      setALTSPoolTokenRead(currentALTSPoolTokenRead)
    },
    [],
  )
  const setCurrentLONGPoolTokenRead = useCallback(
    (currentLONGPoolTokenRead) => {
      setLONGPoolTokenRead(currentLONGPoolTokenRead)
    },
    [],
  )

  return {
    ethToken,
    setCurrentETHToken,
    btcToken,
    setCurrentBTCToken,
    mimToken,
    setCurrentMIMToken,
    altsToken,
    setCurrentALTSToken,
    longToken,
    setCurrentLongToken,

    altsPoolToken,
    setCurrentALTSPoolToken,
    longPoolToken,
    setCurrentLONGPoolToken,

    ethTokenRead,
    setCurrentETHTokenRead,
    btcTokenRead,
    setCurrentBTCTokenRead,
    mimTokenRead,
    setCurrentMIMTokenRead,
    altsTokenRead,
    setCurrentALTSTokenRead,
    longTokenRead,
    setCurrentLongTokenRead,

    altsPoolTokenRead,
    setCurrentALTSPoolTokenRead,
    longPoolTokenRead,
    setCurrentLONGPoolTokenRead,
  }
}
