import React, { useState, useEffect, useContext } from 'react'
import { ThemeContext } from '../../state/ThemeContext'
import eth from '../../assets/images/eth.png'
import alts from '../../assets/images/ALTS_logo.png'
import percentageIcon from '../../assets/images/ratio.png'
import './vault.css'
import { RPC_URL, ZERO_ADDRESS, CHAIN_ID, WETH } from '../../utils/constants'
import { ethers, BigNumber } from 'ethers'
import NumberFormat from 'react-number-format'
import longtermJson from '../../contracts/longterm.json'
import ERC20Json from '../../contracts/ERC20.json'
import SignerContext from '../../state/SignerContext'
import { Link } from 'react-router-dom'
import {
  notifyUser,
  toUSD,
  errorNotification,
  getSafeRemoveCollateral,
  getSafeMint,
} from '../../utils/utils'

const WETHVault = () => {
  const { isDarkMode } = useContext(ThemeContext)
  const signer = useContext(SignerContext)

  // Global
  const [isLoading, setIsLoading] = useState(true)
  const [isVaultCreated, setIsVaultCreated] = useState(false)
  const [isApproved, setIsApproved] = useState(false)
  const [address, setAddress] = useState(ZERO_ADDRESS)
  const [warningOpen, setWarningOpen] = useState(true)
  const [networkWarningOpen, setNetworkWarningOpen] = useState(true)
  const [chainId, setChainId] = useState(1)
  const [ethVault, setEthVault] = useState('')
  const [ethToken, setEthToken] = useState('')
  const [maticOracle, setMaticOracle] = useState('')
  const [ethOracle, setEthOracle] = useState('')
  const [altsOracle, setAltsOracle] = useState('')

  // User stats
  const [collateralBalance, setCollateralBalance] = useState('0.00')
  const [collateralBalanceUsd, setCollateralBalanceUsd] = useState('0.00')
  const [vaultRatio, setVaultRatio] = useState(0)
  const [vaultStatus, setVaultStatus] = useState('N/A')
  const [vaultStatusColor, setVaultStatusColor] = useState('#333')
  const [depositedCollateral, setDepositedCollateral] = useState('0.00')
  const [depositedCollateralUsd, setDepositedCollateralUsd] = useState('0.00')
  const [vaultDebt, setVaultDebt] = useState('0.00')
  const [vaultDebtUsd, setVaultDebtUsd] = useState('0.00')
  const [vaultId, setVaultId] = useState(0)

  // Based on user inputs
  const [addCollateralAmount, setAddCollateralAmount] = useState('')
  const [addCollateralAmountUsd, setAddCollateralAmountUsd] = useState('0')
  const [removeCollateralAmount, setRemoveCollateralAmount] = useState('')
  const [removeCollateralAmountUsd, setRemoveCollateralAmountUsd] = useState(
    '0',
  )
  const [mintAltsAmount, setMintAltsAmount] = useState('')
  const [mintAltsAmountUsd, setMintAltsAmountUsd] = useState('0')
  const [burnAltsAmount, setBurnAltsAmount] = useState('')
  const [burnAltsAmountUsd, setBurnAltsAmountUsd] = useState('0')
  const [burnFee, setBurnFee] = useState('0.00')

  useEffect(() => {
    const loadData = async () => {
      const provider = new ethers.providers.JsonRpcProvider(RPC_URL)
      const contracts = longtermJson[137].matic.contracts

      // Set required contracts
      const MATICOracle = new ethers.Contract(
        contracts.MATICOracle.address,
        contracts.MATICOracle.abi,
        provider,
      )
      setMaticOracle(MATICOracle)

      const ETHOracle = new ethers.Contract(
        contracts.ETHOracle.address,
        contracts.ETHOracle.abi,
        provider,
      )
      setEthOracle(ETHOracle)

      const ALTSOracle = new ethers.Contract(
        contracts.ALTSOracle.address,
        contracts.ALTSOracle.abi,
        provider,
      )
      setAltsOracle(ALTSOracle)

      const ETHVault = new ethers.Contract(
        contracts.ETHVaultHandler.address,
        contracts.ETHVaultHandler.abi,
        provider,
      )

      const ETHToken = new ethers.Contract(WETH, ERC20Json.abi, provider)

      if (signer.signer) {
        const currentChainId = await signer.signer.getChainId()
        setChainId(parseInt(currentChainId))

        const ETHVaultSigner = new ethers.Contract(
          contracts.ETHVaultHandler.address,
          contracts.ETHVaultHandler.abi,
          signer.signer,
        )
        setEthVault(ETHVaultSigner)

        const ETHTokenSigner = new ethers.Contract(
          WETH,
          ERC20Json.abi,
          signer.signer,
        )
        setEthToken(ETHTokenSigner)

        const currentAddress = await signer.signer.getAddress()
        setAddress(currentAddress)

        const approved = await ETHToken.allowance(
          currentAddress,
          ETHVault.address,
        )

        if (approved.toString() !== '0') {
          setIsApproved(true)
        } else {
          setIsApproved(false)
        }

        const userToVault = await ETHVault.userToVault(currentAddress)
        const userToVaultInt = userToVault.toString()

        setVaultId(parseInt(userToVaultInt))

        if (parseInt(userToVaultInt) === 0) {
          setIsVaultCreated(false)
        } else {
          setIsVaultCreated(true)
        }

        // Get oracle prices
        const ethUSDRes = await ETHOracle.getLatestAnswer()
        const ethUSD = ethers.utils.formatUnits(ethUSDRes.toString(), 8)

        const altsUSDRes = await ALTSOracle.getLatestAnswer()
        const altsUSD = ethers.utils.formatUnits(altsUSDRes.toString(), 8)

        // Get user stats
        const userVaultCounter = await ETHVault.userToVault(address)
        const userVaultCounterInt = parseInt(userVaultCounter.toString())

        const userVaultRaw = await ETHVault.vaults(userVaultCounterInt)
        const currentDepositedCollateral = ethers.utils.formatEther(
          userVaultRaw[1].toString(),
        )
        const currentDepositedCollateralUsd =
          currentDepositedCollateral * ethUSD
        setDepositedCollateral(currentDepositedCollateral)
        setDepositedCollateralUsd(currentDepositedCollateralUsd)

        const currentVaultDebt = ethers.utils.formatEther(
          userVaultRaw[2].toString(),
        )
        const currentVaultDebtUsd = parseFloat(currentVaultDebt * altsUSD)
        setVaultDebt(currentVaultDebt)
        setVaultDebtUsd(currentVaultDebtUsd)

        const userEthBalanceRaw = await ETHToken.balanceOf(address)
        const userEthBalance = ethers.utils.formatEther(
          userEthBalanceRaw.toString(),
        )
        const userEthBalanceUsd = parseFloat(userEthBalance * ethUSD)
        setCollateralBalance(userEthBalance)
        setCollateralBalanceUsd(userEthBalanceUsd)

        const currentVaultRatio =
          parseFloat(depositedCollateralUsd / vaultDebtUsd) * 100
        setVaultRatio(currentVaultRatio)

        if (vaultDebtUsd === 0) {
          setVaultRatio(0)
        }

        if (vaultRatio >= 250) {
          setVaultStatus('SAFE')
          setVaultStatusColor('#2a8947')
        } else if (vaultRatio < 250 && vaultRatio >= 200) {
          setVaultStatus('WARNING')
          setVaultStatusColor('#f28040')
        } else if (vaultRatio < 200 && vaultRatio !== 0) {
          setVaultStatus('DANGER')
          setVaultStatusColor('#e84142')
        } else if (vaultRatio === 0) {
          setVaultStatus('N/A')
          setVaultStatusColor('#333')
        }

        setIsLoading(false)
      }
    }

    loadData()
  }, [
    vaultRatio,
    depositedCollateralUsd,
    vaultDebt,
    vaultDebtUsd,
    signer.signer,
    address,
    isVaultCreated,
    depositedCollateral,
    chainId,
  ])

  // Utilities
  const refresh = () => {
    window.location.reload()
  }

  const handleVaultWarning = () => setWarningOpen(false)

  const handleNetworkWarning = () => setNetworkWarningOpen(false)

  const approveValue = BigNumber.from(
    '1157920892373161954235709850086879078532699', // Infinite Approval
  )

  const minRatio = 150

  // Oracle functions
  const altsPrice = async () => {
    const altsPriceUsdRes = await altsOracle.getLatestAnswer()
    const altsPriceUsd = ethers.utils.formatUnits(altsPriceUsdRes.toString(), 8)

    return altsPriceUsd
  }

  const maticPrice = async () => {
    const maticPriceUsdRes = await maticOracle.getLatestAnswer()
    const maticPriceUsd = ethers.utils.formatUnits(
      maticPriceUsdRes.toString(),
      8,
    )

    return maticPriceUsd
  }

  const collateralPrice = async () => {
    const collateralPriceUsdRes = await ethOracle.getLatestAnswer()
    const collateralPriceUsd = ethers.utils.formatUnits(
      collateralPriceUsdRes.toString(),
      8,
    )

    return collateralPriceUsd
  }

  // If vault is not created
  const createVault = async () => {
    if (!isVaultCreated) {
      try {
        const tx = await ethVault.createVault()

        notifyUser(tx, refresh)
      } catch (error) {
        console.error(error)

        if (error.code === 4001) {
          errorNotification('User rejected transaction signature.')
        } else {
          errorNotification('Transaction failed.')
        }
      }
    }
  }

  // Approve WETH
  const approveWETH = async () => {
    try {
      const tx = await ethToken.approve(ethVault.address, approveValue)

      notifyUser(tx, refresh)
    } catch (error) {
      console.error(error)

      if (error.code === 4001) {
        errorNotification('User rejected transaction signature.')
      } else {
        errorNotification('Transaction failed.')
      }
    }
  }

  // onChange methods
  const onChangeAddCollateral = async (event) => {
    setAddCollateralAmount(event.target.value)

    if (event.target.value !== '') {
      const currentPrice = await collateralPrice()
      let usd = toUSD(currentPrice, event.target.value)

      if (!usd) {
        usd = 0
      }

      setAddCollateralAmountUsd(usd)
    } else {
      setAddCollateralAmount('')
      setAddCollateralAmountUsd('0')
    }
  }

  const onChangeRemoveCollateral = async (event) => {
    setRemoveCollateralAmount(event.target.value)

    if (event.target.value !== '') {
      const currentPrice = await collateralPrice()
      let usd = toUSD(currentPrice, event.target.value)

      if (!usd) {
        usd = 0
      }

      setRemoveCollateralAmountUsd(usd)
    } else {
      setRemoveCollateralAmount('')
      setRemoveCollateralAmountUsd('0')
    }
  }

  const onChangeMint = async (event) => {
    setMintAltsAmount(event.target.value)

    if (event.target.value !== '') {
      const currentAltsPrice = await altsPrice()
      let usd = toUSD(currentAltsPrice, event.target.value)

      if (!usd) {
        usd = 0
      }

      setMintAltsAmountUsd(usd)
    } else {
      setMintAltsAmount('')
      setMintAltsAmountUsd('0')
    }
  }

  const onChangeBurn = async (event) => {
    try {
      setBurnAltsAmount(event.target.value)
      if (event.target.value !== '') {
        const currentMaticPrice = await maticPrice()
        const currentAltsPrice = await altsPrice()
        let usd = toUSD(currentAltsPrice, event.target.value)

        if (!usd) {
          usd = 0
        }

        setBurnAltsAmountUsd(usd)

        const currentBurnFeeUsd = parseFloat(usd) * 0.02
        const currentBurnFeeMatic = parseFloat(
          currentBurnFeeUsd / currentMaticPrice,
        )

        setBurnFee(currentBurnFeeMatic)
      } else {
        setBurnAltsAmount('')
        setBurnAltsAmountUsd('0')
        setBurnFee('0')
      }
    } catch (error) {
      console.error(error)
      setBurnAltsAmountUsd('0')
      setBurnFee('0')
    }
  }

  // Form methods
  const addCollateral = async () => {
    if (addCollateralAmount && addCollateralAmount !== '') {
      try {
        const amount = ethers.utils.parseUnits(addCollateralAmount, 18)

        const tx = await ethVault.addCollateral(amount)

        const parsedAmount = ethers.utils.formatEther(amount)
        const newDepositedCollateral =
          depositedCollateral + parseInt(parsedAmount)

        setDepositedCollateral(newDepositedCollateral)

        notifyUser(tx, refresh)
      } catch (error) {
        console.error(error)
        if (error.code === 4001) {
          errorNotification('User rejected transaction signature.')
        } else {
          errorNotification('Insufficient funds.')
        }
      }

      setAddCollateralAmount('')
      setAddCollateralAmountUsd('0')
    } else {
      errorNotification("Amount can't be zero.")
    }
  }

  const maxAddCollateral = async (e) => {
    e.preventDefault()

    let balance = '0'

    balance = ethers.utils.formatEther(await ethToken.balanceOf(address))

    const currentPrice = await collateralPrice()
    setAddCollateralAmount(balance)

    let usd = toUSD(currentPrice, balance)

    if (!usd) {
      usd = 0
    }
    setAddCollateralAmountUsd(usd.toString())
  }

  const removeCollateral = async () => {
    if (removeCollateralAmount) {
      const amount = ethers.utils.parseUnits(removeCollateralAmount, 18)

      try {
        const tx = await ethVault.removeCollateral(amount)

        const parsedAmount = ethers.utils.formatEther(amount)
        const newDepositedCollateral =
          depositedCollateral - parseInt(parsedAmount)

        setDepositedCollateral(newDepositedCollateral)

        notifyUser(tx, refresh)
      } catch (error) {
        console.error(error)
        if (error.code === 4001) {
          errorNotification('User rejected transaction signature.')
        } else {
          errorNotification('Not enough collateral in the vault.')
        }
      }

      setRemoveCollateralAmount('')
      setRemoveCollateralAmountUsd('0')
    } else {
      errorNotification("Amount can't be zero.")
    }
  }

  const safeRemoveCollateral = async (e) => {
    e.preventDefault()

    const currentPrice = await collateralPrice()
    const currentAltsPrice = await altsPrice()

    const collateralToRemove = await getSafeRemoveCollateral(
      minRatio,
      depositedCollateral,
      currentPrice,
      currentAltsPrice,
      vaultDebt,
    )

    setRemoveCollateralAmount(collateralToRemove.toString())
    let usd = toUSD(currentPrice, collateralToRemove.toString())

    if (!usd) {
      usd = 0
    }

    setRemoveCollateralAmountUsd(usd.toString())
  }

  const mintALTS = async () => {
    if (mintAltsAmount && mintAltsAmount !== '') {
      try {
        const amount = ethers.utils.parseEther(mintAltsAmount, 18)
        const tx = await ethVault.mint(amount)

        const parsedAmount = ethers.utils.formatEther(amount)
        const newVaultDebt = vaultDebt + parseInt(parsedAmount)

        setVaultDebt(newVaultDebt)

        notifyUser(tx, refresh)
      } catch (error) {
        console.error(error)
        if (error.code === 4001) {
          errorNotification('User rejected transaction signature.')
        } else {
          errorNotification('Insufficient collateral in the vault.')
        }
      }
      setMintAltsAmount('')
      setMintAltsAmountUsd('0')
    } else {
      errorNotification("Field can't be empty.")
    }
  }

  const safeMintALTS = async (e) => {
    e.preventDefault()

    const currentPrice = await collateralPrice()
    const currentAltsPrice = await altsPrice()

    const safeMint = await getSafeMint(
      minRatio,
      depositedCollateral,
      currentPrice,
      currentAltsPrice,
      vaultDebt,
    )

    setMintAltsAmount(safeMint.toString())
    let usd = toUSD(currentAltsPrice, safeMint.toString())

    if (!usd) {
      usd = 0
    }

    setMintAltsAmountUsd(usd.toString())
  }

  const burnALTS = async () => {
    if (burnAltsAmount) {
      try {
        const amount = ethers.utils.parseEther(burnAltsAmount)

        const altsPriceUsd = await altsPrice()
        const ethPriceUsd = await maticPrice()

        const altsAmount = ethers.utils.formatEther(amount.toString())
        const altsAmountUsd = parseFloat(altsAmount) * parseFloat(altsPriceUsd)

        const currentBurnFeeInMatic =
          parseFloat(altsAmountUsd * 0.0201) / parseFloat(ethPriceUsd)

        const burnFeeInMatic = ethers.utils.parseEther(
          currentBurnFeeInMatic.toString().slice(0, 12),
        )

        const tx = await ethVault.burn(amount, {
          value: burnFeeInMatic,
        })

        const parsedAmount = ethers.utils.formatEther(amount)
        const newVaultDebt = vaultDebt - parseInt(parsedAmount)

        setVaultDebt(newVaultDebt)

        notifyUser(tx, refresh)
      } catch (error) {
        console.error(error)
        if (error.code === 4001) {
          errorNotification('User rejected transaction signature.')
        } else {
          errorNotification('Burn amount too high.')
        }
      }
      setBurnAltsAmount('')
      setBurnAltsAmountUsd('0')
      setBurnFee('0')
    } else {
      errorNotification("Amount can't be zero.")
    }
  }

  const maxBurnALTS = async (e) => {
    e.preventDefault()

    const currentMaticPrice = await maticPrice()
    const currentAltsPrice = await altsPrice()
    const currentAltsBalance = vaultDebt
    const currentVault = await ethVault.vaults(vaultId)
    const currentVaultDebt = ethers.utils.formatEther(
      currentVault[2].toString(),
    )

    let balanceFormat = '0'

    if (currentAltsBalance > currentVaultDebt) {
      balanceFormat = ethers.utils.formatEther(currentAltsBalance)
    } else {
      balanceFormat = vaultDebt
    }

    setBurnAltsAmount(balanceFormat)
    let usd = toUSD(currentAltsPrice, balanceFormat)

    if (!usd) {
      usd = 0
    }

    if (balanceFormat !== '0') {
      const currentBurnFeeUsd = parseFloat(usd) * 0.02
      const currentBurnFeeMatic = parseFloat(
        currentBurnFeeUsd / currentMaticPrice,
      )

      setBurnFee(currentBurnFeeMatic)
    } else {
      setBurnFee('0')
    }
  }

  return isVaultCreated ? (
    <div
      className={`vaults-container ${
        isDarkMode ? 'trade-container-dark-mode' : 'trade-container'
      }`}
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <button className="btn regular-btn bold border-rad-05 back-to-vaults-btn">
        <Link
          to="/vaults"
          className="trade-card-analytics-link"
          style={{
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.175rem',
          }}
          type="button"
        >
          ↩ Back to Vaults
        </Link>
      </button>
      <h1 className="text-center bold mb-5 4" style={{ fontSize: '2.75rem' }}>
        Wrapped Ethereum (WETH) Vault
      </h1>
      {!isApproved ? (
        <>
          <div className="approve-btn-container">
            <button
              className="btn regular-btn create-vault-btn"
              type="button"
              onClick={approveWETH}
            >
              Approve WETH
            </button>
          </div>
          <br /> <br />
        </>
      ) : (
        <br />
      )}
      {warningOpen && (
        <div
          className={`${
            isDarkMode ? 'c-ratio-alert-dark-mode' : 'c-ratio-alert'
          }`}
        >
          <strong>
            ⚠️ Make sure to always have a ratio above the minimum ratio (150%)
            to avoid getting liquidated.
          </strong>
          <span className="dismiss-alert" onClick={handleVaultWarning}>
            X
          </span>
        </div>
      )}
      {signer.signer && chainId !== CHAIN_ID && networkWarningOpen && (
        <div
          className={`${
            isDarkMode ? 'c-ratio-alert-dark-mode' : 'c-ratio-alert'
          }`}
        >
          <strong>
            ⚠️ Please double check that your wallet is connected to the{' '}
            <Link
              to="/add-polygon"
              className="trade-card-analytics-link doc-link"
              style={{
                color: '#0066ff',
              }}
            >
              Polygon (Matic) mainnet.
            </Link>
          </strong>
          <span className="dismiss-alert" onClick={handleNetworkWarning}>
            X
          </span>
        </div>
      )}
      <span
        className="text-center"
        style={{
          display: 'block',
          fontSize: '2rem',
          fontWeight: 'bold',
        }}
      >
        {signer.signer && isLoading && (
          <>
            (Loading Balances...)
            <br /> <br /> <br />
          </>
        )}
      </span>
      <div className="vault-grid">
        <div className="user-stats">
          <div
            className={`${
              isDarkMode ? 'collateral-balance-dark-mode' : 'collateral-balance'
            }`}
          >
            <img src={eth} alt="ETH" width="45" height="45" />
            <div className="collateral-balance-inner">
              <h3 className="text-left coll-name">WETH Balance</h3>
              <div className="collateral-balance-value">
                <h3 className="text-right">
                  <NumberFormat
                    className="number"
                    value={!isLoading && collateralBalance}
                    displayType="text"
                    thousandSeparator
                    prefix=""
                    decimalScale={3}
                  />{' '}
                  WETH
                </h3>
                <p>
                  $
                  <NumberFormat
                    className="number"
                    value={!isLoading && collateralBalanceUsd}
                    displayType="text"
                    thousandSeparator
                    prefix=""
                    decimalScale={2}
                  />
                </p>
              </div>
            </div>
          </div>

          <div
            className={`${
              isDarkMode ? 'vault-ratio-dark-mode' : 'vault-ratio'
            }`}
          >
            <img
              src={percentageIcon}
              alt="%"
              width="42"
              height="42"
              style={{ backgroundColor: '#ffffff', borderRadius: '100%' }}
            />
            <div className="vault-ratio-inner">
              <h3 className="text-left ratio-name">Vault Ratio</h3>
              <div className="vault-ratio-value">
                <h3 className="text-right">
                  <NumberFormat
                    className="number"
                    value={!isLoading && vaultRatio !== 0 && vaultRatio}
                    displayType="text"
                    thousandSeparator
                    prefix=""
                    decimalScale={2}
                  />
                  {vaultRatio === 0 && 'N/A '}%
                </h3>
                <div
                  className="badge"
                  style={{
                    backgroundColor: vaultStatusColor,
                    fontSize: '1rem',
                    borderRadius: '7px',
                  }}
                >
                  <p
                    className="vault-ratio-status bold"
                    style={{
                      color: '#fff',
                    }}
                  >
                    {vaultStatus}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${
            isDarkMode
              ? 'add-remove-collateral-dark-mode'
              : 'add-remove-collateral'
          }`}
        >
          <div className="add-remove-collateral-header">
            <h2 className="text-left vault-subtitle">Deposited Collateral</h2>
            <div className="deposited-collateral-info">
              <div className="coll-name-with-icon">
                <img src={eth} alt="ETH" width="30" height="30" />
                <span className="deposited-collateral-balance">
                  <NumberFormat
                    className="number"
                    value={!isLoading && depositedCollateral}
                    displayType="text"
                    thousandSeparator
                    prefix=""
                    decimalScale={3}
                  />{' '}
                  WETH
                </span>
              </div>

              <span style={{ marginTop: '0.3rem' }}>
                <NumberFormat
                  className="deposited-collateral-balance-usd text-muted"
                  value={!isLoading && depositedCollateralUsd}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                />
              </span>
            </div>
          </div>

          <hr className="vault-hr" style={{ marginTop: '0' }} />

          <form className="vault-card-container">
            <div className="form-group upper-input-container">
              <div className="input-labels-container">
                <label className="form-label bold input-title">
                  Add Collateral
                </label>
                <label htmlFor="" className="max form-label">
                  <a
                    href="/"
                    className="number bold max-text"
                    onClick={maxAddCollateral}
                  >
                    MAX
                  </a>
                </label>
              </div>
              <div className="input-group">
                <input
                  type="number"
                  name="addCollateral"
                  className="form-control vault-input"
                  value={addCollateralAmount}
                  onChange={onChangeAddCollateral}
                  placeholder="0"
                />
                <div className="input-group-append text-muted input-text-usd">
                  $
                  <NumberFormat
                    className="number"
                    value={addCollateralAmountUsd}
                    displayType="text"
                    thousandSeparator
                    prefix=""
                    decimalScale={2}
                  />
                </div>
                <div className="input-group-append">
                  <button
                    className="btn regular-btn bold vault-btn"
                    type="button"
                    onClick={addCollateral}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>

            <div className="form-group lower-input-container">
              <div className="input-labels-container">
                <label className="form-label bold input-title">
                  Remove Collateral
                </label>
                <label htmlFor="" className="max form-label">
                  <a
                    href="/"
                    className="number bold max-text"
                    onClick={safeRemoveCollateral}
                  >
                    MAX SAFE
                  </a>
                </label>
              </div>
              <div className="input-group">
                <input
                  type="number"
                  name="removeCollateral"
                  className="form-control vault-input"
                  onChange={onChangeRemoveCollateral}
                  value={removeCollateralAmount}
                  placeholder="0"
                />
                <div className="input-group-append text-muted input-text-usd">
                  $
                  <NumberFormat
                    className="number"
                    value={removeCollateralAmountUsd}
                    displayType="text"
                    thousandSeparator
                    prefix=""
                    decimalScale={2}
                  />
                </div>
                <div className="input-group-append">
                  <button
                    className="btn regular-btn bold vault-btn"
                    type="button"
                    onClick={removeCollateral}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div
          className={`${
            isDarkMode ? 'mint-burn-alts-dark-mode' : 'mint-burn-alts'
          }`}
        >
          <div className="mint-burn-alts-header">
            <h2 className="text-left vault-subtitle">Vault Debt</h2>

            <div className="mint-burn-alts-info">
              <div className="debt-amount-with-icon">
                <img
                  src={alts}
                  alt="ALTS"
                  width="30"
                  height="30"
                  style={{
                    backgroundColor: '#ffffff',
                    borderRadius: '100%',
                    border: '2px solid #0066ff',
                  }}
                />
                <span className="debt-amount-balance">
                  <NumberFormat
                    className="number"
                    value={!isLoading && vaultDebt}
                    displayType="text"
                    thousandSeparator
                    prefix=""
                    decimalScale={3}
                  />{' '}
                  ALTS
                </span>
              </div>

              <span style={{ marginTop: '0.3rem' }}>
                <NumberFormat
                  className="debt-amount-balance-usd text-muted"
                  value={!isLoading && vaultDebtUsd}
                  displayType="text"
                  thousandSeparator
                  prefix="$"
                  decimalScale={2}
                />
              </span>
            </div>
          </div>

          <hr className="vault-hr" style={{ marginTop: '0' }} />

          <form className="vault-card-container">
            <div className="form-group upper-input-container">
              <div className="input-labels-container">
                <label className="form-label bold input-title">Mint ALTS</label>
                <label htmlFor="" className="max form-label">
                  <a
                    href="/"
                    className="number bold max-text"
                    onClick={safeMintALTS}
                  >
                    MAX SAFE
                  </a>
                </label>
              </div>
              <div className="input-group">
                <input
                  type="number"
                  name="mintAlts"
                  className="form-control vault-input"
                  value={mintAltsAmount}
                  onChange={onChangeMint}
                  placeholder="0"
                />
                <div className="input-group-append text-muted input-text-usd">
                  $
                  <NumberFormat
                    className="number"
                    value={mintAltsAmountUsd}
                    displayType="text"
                    thousandSeparator
                    prefix=""
                    decimalScale={2}
                  />
                </div>
                <div className="input-group-append">
                  <button
                    className="btn regular-btn bold vault-btn"
                    type="button"
                    onClick={mintALTS}
                  >
                    Mint
                  </button>
                </div>
              </div>
            </div>

            <div className="form-group lower-input-container lower-input-container-burn-fee">
              <div className="input-labels-container">
                <label className="form-label bold input-title">Burn ALTS</label>
                <label htmlFor="" className="max form-label">
                  <a
                    href="/"
                    className="number bold max-text"
                    onClick={maxBurnALTS}
                  >
                    MAX
                  </a>
                </label>
              </div>
              <div className="input-group">
                <input
                  type="number"
                  value={burnAltsAmount}
                  onChange={onChangeBurn}
                  name="burnAlts"
                  className="form-control vault-input"
                  placeholder="0"
                />
                <div className="input-group-append text-muted input-text-usd">
                  $
                  <NumberFormat
                    className="number"
                    value={burnAltsAmountUsd}
                    displayType="text"
                    thousandSeparator
                    prefix=""
                    decimalScale={2}
                  />
                </div>
                <div className="input-group-append">
                  <button
                    className="btn regular-btn bold vault-btn"
                    type="button"
                    onClick={burnALTS}
                  >
                    Burn
                  </button>
                </div>
              </div>

              <div
                className="burn-fee-title"
                style={{
                  color: isDarkMode ? 'rgb(224, 220, 220)' : '#6c757d',
                }}
              >
                Burn Fee:{' '}
                <span className="burn-fee-value">
                  <NumberFormat
                    className="number"
                    value={burnFee}
                    displayType="text"
                    thousandSeparator
                    prefix=""
                    decimalScale={4}
                  />
                </span>{' '}
                MATIC
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={`${
        isDarkMode ? 'trade-container-dark-mode' : 'trade-container'
      }`}
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <button className="btn regular-btn bold border-rad-05 back-to-vaults-btn">
        <Link
          to="/vaults"
          className="trade-card-analytics-link"
          style={{
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.175rem',
          }}
          type="button"
        >
          ↩ Back to Vaults
        </Link>
      </button>

      <div className="create-vault-container ">
        <img src={eth} alt="ETH" width="70" height="70" />
        <h1
          className="text-center bold mb-5 mt-4"
          style={{ fontSize: '2.75rem' }}
        >
          Wrapped Ethereum (WETH) Vault
        </h1>

        <h2 className="text-center bold create-vault-title">Create Vault</h2>

        <p className="text-center create-vault-subtitle">
          No vault created yet. Please create vault & approve WETH as collateral
          to start minting ALTS tokens.
          <br /> <br /> <br /> <br />
          <span
            style={{
              fontSize: '1.5rem',
            }}
          >
            <span
              style={{
                textDecoration: 'underline',
                fontWeight: 'bold',
              }}
            >
              NOTE:
            </span>{' '}
            Make sure your wallet is connected and it's{' '}
            <Link
              to="/add-polygon"
              className="trade-card-analytics-link doc-link"
              style={{
                color: '#0066ff',
                fontWeight: 'bold',
              }}
            >
              on the Polygon (Matic) mainnet.
            </Link>
          </span>
        </p>
        <button
          className="btn regular-btn create-vault-btn"
          type="button"
          onClick={createVault}
        >
          Create Vault
        </button>
      </div>
    </div>
  )
}

export default WETHVault
