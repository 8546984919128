import React from 'react'

export const REWARDS_DEFAULT_VALUE = {
  setCurrentETHReward: () => {},
  setCurrentBTCReward: () => {},
  setCurrentMIMReward: () => {},

  setCurrentALTSPoolReward: () => {},
  setCurrentLONGPoolReward: () => {},

  setCurrentETHRewardRead: () => {},
  setCurrentBTCRewardRead: () => {},
  setCurrentMIMRewardRead: () => {},

  setCurrentALTSPoolRewardRead: () => {},
  setCurrentLONGPoolRewardRead: () => {},
}

const rewardsContext = React.createContext(REWARDS_DEFAULT_VALUE)

export default rewardsContext
