import React, { useContext, useState, useEffect } from 'react'
import './longtermbulls.css'
import { ThemeContext } from '../../state/ThemeContext'
import { ethers } from 'ethers'
import nftJson from '../../contracts/longtermBulls.json'
import SignerContext from '../../state/SignerContext'
import {
  RPC_URL,
  CHAIN_ID,
  NFT_MAX_SUPPLY,
  ZERO_ADDRESS,
} from '../../utils/constants'
import NumberFormat from 'react-number-format'
import { Link } from 'react-router-dom'
import collectionLogo from '../../assets/images/logo_170.png'
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger'
import Tooltip from 'react-bootstrap/esm/Tooltip'
import { notifyUser, errorNotification } from '../../utils/utils'
import openSea from '../../assets/images/opensea.svg'

// const NFTCard = ({
//   name,
//   status,
//   price,
//   buyLink,
//   isExclusive,
//   isDisabled,
//   nftSrc,
//   isDarkMode
// }) => {
//   return (
//     <div
//       className={`${isDarkMode ? 'nft-card-dark-mode' : 'nft-card'}
//        ${isExclusive && 'exclusive-nft'}
//       `}
//     >
//       {/* eslint-disable-next-line */}
//       <a
//         target="_blank"
//         href={`https://opensea.io/assets/${buyLink}`}
//         rel="noreferrer"
//       >
//         <div
//           className="nft-image"
//           style={{
//             backgroundImage: `url(${nftSrc ? nftSrc : demoImg})`,
//             cursor: isDisabled && 'not-allowed',
//             backgroundRepeat: isExclusive && 'no-repeat',
//             backgroundPosition: isExclusive && 'center top',
//           }}
//         />
//       </a>

//       <div className="nft-card-block-1">
//         <span className="nft-name bold ml-3 mt-3">{name}</span>
//         <span
//           className={`nft-status bold mr-3 mt-3 ${
//             status === 'On Sale'
//               ? 'badge badge-success onsale-badge'
//               : status === 'Soon'
//               ? 'badge badge-secondary'
//               : 'badge badge-secondary sold-badge'
//           }`}
//           style={{
//             backgroundColor: isDarkMode ? '#f8f9fa' : '#343A40',
//             color: isDarkMode ? '#343A40' : '#ffffff',
//             paddingTop: '7.5px',
//             paddingBottom: '7.5px',
//             fontWeight: 'bold',
//             fontSize: '0.9rem',
//             borderRadius: '5px',
//           }}
//         >
//           {status}
//         </span>
//       </div>

//       <hr />

//       <div className="nft-card-block-2">
//         <span className="nft-price ml-3">
//           <img width="22" height="22" alt="ETH" src={eth} />{' '}
//           <span
//             className="bold"
//             style={{
//               marginLeft: '5px',
//               position: 'relative',
//               top: '1px',
//             }}
//           >
//             {price} WETH
//           </span>
//         </span>
//         <button
//           type="button"
//           className={`mr-3 btn regular-btn bold border-rad-05 ${
//             isDisabled && 'buy-nft-disabled'
//           }`}
//           style={{
//             height: '40px',
//             fontWeight: 'bold',
//             backgroundColor: '#0066ff',
//           }}
//         >
//           {/* eslint-disable-next-line */}
//           <a
//             target="_blank"
//             rel="noreferrer"
//             style={{
//               color: '#fff',
//               fontWeight: 'bold',
//               fontSize: '1.25rem',
//             }}
//             href={`https://opensea.io/assets/${buyLink}`}
//             className={isDisabled && 'buy-nft-disabled'}
//           >
//             Buy
//           </a>
//         </button>
//       </div>
//     </div>
//   )
// }

const NFT = () => {
  const { isDarkMode } = useContext(ThemeContext)
  const signer = useContext(SignerContext)

  const [isLoading, setIsLoading] = useState(true)
  const [chainId, setChainId] = useState(1)
  const [networkWarningOpen, setNetworkWarningOpen] = useState(true)
  const [address, setAddress] = useState(ZERO_ADDRESS)

  const [nftContractSigner, setNftContractSigner] = useState('')
  const [nftsLeft, setNftsLeft] = useState(0)

  useEffect(() => {
    const loadData = async () => {
      const provider = new ethers.providers.JsonRpcProvider(RPC_URL)
      const contract = nftJson.LongTermBull

      const mintContract = new ethers.Contract(
        contract.address,
        contract.abi,
        provider,
      )

      const totalSupply = await mintContract.totalSupply()
      const currentNftsLeft = NFT_MAX_SUPPLY - totalSupply
      setNftsLeft(currentNftsLeft)

      if (signer.signer) {
        const currentChainId = await signer.signer.getChainId()
        setChainId(parseInt(currentChainId))

        const currentAddress = await signer.signer.getAddress()
        setAddress(currentAddress)

        const mintContractSigner = new ethers.Contract(
          contract.address,
          contract.abi,
          signer.signer,
        )
        setNftContractSigner(mintContractSigner)
      }

      setIsLoading(false)
    }

    loadData()
  }, [signer.signer])

  const handleNetworkWarning = () => setNetworkWarningOpen(false)

  const copyAddress = (e) => {
    e.preventDefault()
    const el = document.createElement('input')
    el.value = '0x6F1d3013BFb2AF0a95e3f0Fb1c92996797F21525'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
  }

  const refresh = () => {
    window.location.reload()
  }

  const mint = async () => {
    try {
      const mintPrice = ethers.utils.parseEther('100')

      const tx = await nftContractSigner.mint([address], {
        value: mintPrice,
      })

      notifyUser(tx, refresh)
    } catch (error) {
      console.error(error)
      if (error.code === 4001) {
        errorNotification('User rejected transaction signature.')
      } else {
        errorNotification('Insufficient funds.')
      }
    }
  }

  return (
    <div
      className={`${isDarkMode ? 'nft-container-dark-mode' : 'nft-container'}`}
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="nft-collection-logo">
        <img
          src={collectionLogo}
          alt="Collection Logo"
          width={170}
          height={170}
          style={{
            borderRadius: '100%',
          }}
        />
      </div>

      <h1
        className={`text-center bold ${
          isDarkMode ? 'nft-title-dark-mode' : 'nft-title'
        }`}
      >
        LongTerm Bulls
      </h1>
      <br />

      <p
        className={`text-mute text-center ml-5 mr-5 mb-5 ${
          isDarkMode ? 'early-adopter-note-dark-mode' : 'early-adopter-note'
        }`}
      >
        <strong>Each NFT from the LongTerm Bulls collection</strong> represents
        a unique bull (every long term investor's favorite animal!) with
        distinct characteristics, some of which are more or less scarce. These
        bulls are created using the finest principles of generative art and{' '}
        <strong>there will only ever be 2048 of them.</strong> To stay up to
        date with the latest NFT collection news (& more), make sure to{' '}
        <a
          href="https://twitter.com/LongTermFi"
          target="_blank"
          rel="noreferrer"
          className="doc-link bold"
          style={{
            color: '#0066ff',
          }}
        >
          follow us on Twitter
        </a>
        .
      </p>

      <h2
        className={`text-center bold ${
          isDarkMode ? 'nft-title-dark-mode' : 'nft-title'
        }`}
        style={{
          fontSize: '2.5em',
        }}
      >
        About
      </h2>
      <p
        className={`text-mute text-center ml-5 mr-5 mb-5 ${
          isDarkMode ? 'early-adopter-note-dark-mode' : 'early-adopter-note'
        }`}
      >
        <strong>After minting,</strong> you'll fully own your unique LongTerm
        Bull, and it should also appear as a{' '}
        <strong>
          part of the{' '}
          <a
            href="https://opensea.io/collection/longterm-bulls"
            target="_blank"
            rel="noreferrer"
            className="doc-link"
            style={{
              color: '#0066ff',
              fontWeight: 'bold',
            }}
          >
            collection on OpenSea
          </a>
        </strong>{' '}
        on the Polygon (Matic) network. All NFTs come with{' '}
        <strong>10% royalty on the future sales</strong> for the art creators,
        and can be further bought and sold on OpenSea, with payment options
        which include WETH, DAI and USDC.
      </p>

      <h2
        className={`text-center bold ${
          isDarkMode ? 'nft-title-dark-mode' : 'nft-title'
        }`}
        style={{
          fontSize: '2.5em',
        }}
      >
        Utility
      </h2>

      <p
        className={`text-mute text-center ml-5 mr-5 mb-5 ${
          isDarkMode ? 'early-adopter-note-dark-mode' : 'early-adopter-note'
        }`}
      >
        Every LongTerm Bull holder will be{' '}
        <strong>eligible for the premium services</strong> on the LongTerm
        Finance protocol, and <strong>utility will include:</strong> primary
        access to the new protocol functionalities, various future airdrops,
        discounted burn fees on the platform, and much, much more.
      </p>

      <h2
        className={`text-center bold ${
          isDarkMode ? 'nft-title-dark-mode' : 'nft-title'
        }`}
        style={{
          fontSize: '2.5em',
        }}
      >
        Goals
      </h2>

      <p
        className={`text-mute text-center ml-5 mr-5 mb-5 ${
          isDarkMode ? 'early-adopter-note-dark-mode' : 'early-adopter-note'
        }`}
      >
        We aim to become one of the largest DeFi & NFT projects on the Polygon
        network, while{' '}
        <strong>
          constantly increasing the floor price of our NFTs by providing clear
          value and utility
        </strong>{' '}
        to the ecosystem, all of which will be done with our target user (a true
        long term investor) in mind.
      </p>

      <br />
      <br />
      <br />
      <br />

      <div className="mint-container">
        <h2
          className={`text-center bold ${
            isDarkMode ? 'nft-title-dark-mode' : 'nft-title'
          }`}
          style={{
            fontSize: '2.5em',
          }}
        >
          Minting is Open NOW for Everyone!
        </h2>
        <br />
        <br />
        <div
          style={{
            height: '20px',
          }}
        />
        {signer.signer && chainId !== CHAIN_ID && networkWarningOpen && (
          <div
            className={`${
              isDarkMode ? 'c-ratio-alert-dark-mode' : 'c-ratio-alert'
            }`}
          >
            <strong>
              ⚠️ Please double check that your wallet is connected to the{' '}
              <Link
                to="/add-polygon"
                className="trade-card-analytics-link doc-link"
                style={{
                  color: '#0066ff',
                }}
              >
                Polygon (Matic) mainnet.
              </Link>
            </strong>
            <span className="dismiss-alert" onClick={handleNetworkWarning}>
              X
            </span>
          </div>
        )}
        <p
          className={`text-mute text-center ml-5 mr-5 mb-5 ${
            isDarkMode ? 'early-adopter-note-dark-mode' : 'early-adopter-note'
          }`}
          style={{ fontSize: '1.5em' }}
        >
          <strong>Mint Price:</strong> 100 MATIC
        </p>
        <br />
        <button
          type="button"
          onClick={mint}
          className="btn regular-btn bold text-center"
          style={{
            fontWeight: 'bold',
            fontSize: '1.75em',
            textAlign: 'center',
            margin: '25px 0 25px',
          }}
        >
          Mint
        </button>

        <br />

        <p
          className={`text-mute text-center ml-5 mr-5 mb-5 ${
            isDarkMode ? 'early-adopter-note-dark-mode' : 'early-adopter-note'
          }`}
          style={{
            fontSize: '1.5em',
          }}
        >
          <strong>LongTerm Bulls Left to be Minted: </strong>
          <NumberFormat
            className="number"
            value={!isLoading && 1347}
            displayType="text"
            thousandSeparator
            prefix=""
            decimalScale={0}
          />{' '}
          <strong>/</strong>{' '}
          <NumberFormat
            className="number"
            value={2048}
            displayType="text"
            thousandSeparator
            prefix=""
            decimalScale={0}
          />
        </p>

        <div
          style={{
            height: '35px',
          }}
        />

        <p
          className={`text-mute text-center ml-5 mr-5 mb-5 ${
            isDarkMode ? 'early-adopter-note-dark-mode' : 'early-adopter-note'
          }`}
        >
          <strong
            style={{
              textDecoration: 'underline',
            }}
          >
            Contract Address:
          </strong>{' '}
          <span className="address">
            <OverlayTrigger
              key="bottom"
              placement="bottom"
              overlay={
                <Tooltip
                  id="tooltip-bottom"
                  style={{
                    fontWeight: 'bold',
                  }}
                >
                  Click to Copy
                </Tooltip>
              }
            >
              <a
                href="/"
                onClick={copyAddress}
                className="address"
                style={{
                  color: isDarkMode ? '#ffffff' : '#000000',
                }}
              >
                0x6F1d3013BFb2AF0a95e3f0Fb1c92996797F21525
              </a>
            </OverlayTrigger>
          </span>{' '}
        </p>

        <div
          style={{
            height: '12.5px',
          }}
        />

        <a
          className="doc-link"
          style={{
            fontWeight: 'bold',
          }}
          href="https://opensea.io/collection/longterm-bulls"
          target="_blank"
          rel="noreferrer"
        >
          <img src={openSea} alt="OpenSea" width={45} height={45} />
          <span className="explore-link doc-link">
            Explore the Collection on OpenSea
          </span>
        </a>
      </div>

      <br />
      <br />
      <br />
      <br />

      <br />
      <br />
      <br />
    </div>
  )
}

export default NFT
