import { useState, useCallback } from 'react'

export const useOracles = () => {
  const [ethOracle, setETHOracle] = useState()
  const [btcOracle, setBTCOracle] = useState()
  const [mimOracle, setMIMOracle] = useState()
  const [altsOracle, setALTSOracle] = useState()

  const [ethOracleRead, setETHOracleRead] = useState()
  const [btcOracleRead, setBTCOracleRead] = useState()
  const [mimOracleRead, setMIMOracleRead] = useState()
  const [altsOracleRead, setALTSOracleRead] = useState()

  const setCurrentETHOracle = useCallback((currentETHOracle) => {
    setETHOracle(currentETHOracle)
  }, [])
  const setCurrentBTCOracle = useCallback((currentBTCOracle) => {
    setBTCOracle(currentBTCOracle)
  }, [])
  const setCurrentMIMOracle = useCallback((currentMIMOracle) => {
    setMIMOracle(currentMIMOracle)
  }, [])
  const setCurrentALTSOracle = useCallback((currentALTSOracle) => {
    setALTSOracle(currentALTSOracle)
  }, [])

  const setCurrentETHOracleRead = useCallback((currentETHOracleRead) => {
    setETHOracleRead(currentETHOracleRead)
  }, [])
  const setCurrentBTCOracleRead = useCallback((currentBTCOracleRead) => {
    setBTCOracleRead(currentBTCOracleRead)
  }, [])
  const setCurrentMIMOracleRead = useCallback((currentMIMOracleRead) => {
    setMIMOracleRead(currentMIMOracleRead)
  }, [])
  const setCurrentALTSOracleRead = useCallback((currentALTSOracleRead) => {
    setALTSOracleRead(currentALTSOracleRead)
  }, [])

  return {
    ethOracle,
    setCurrentETHOracle,
    btcOracle,
    setCurrentBTCOracle,
    mimOracle,
    setCurrentMIMOracle,
    altsOracle,
    setCurrentALTSOracle,
    ethOracleRead,
    setCurrentETHOracleRead,
    btcOracleRead,
    setCurrentBTCOracleRead,
    mimOracleRead,
    setCurrentMIMOracleRead,
    altsOracleRead,
    setCurrentALTSOracleRead,
  }
}
