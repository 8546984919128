import React, { useContext } from 'react'
import './borrow.css'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../../state/ThemeContext'
import long from '../../assets/images/LONG_logo.png'
import eth from '../../assets/images/eth.png'
import dai from '../../assets/images/dai.png'

const BorrowLONGWETH = () => {
  const { isDarkMode } = useContext(ThemeContext)

  const approveFn = () => {
    alert('Coming Soon!')
  }

  return (
    <div
      className={`${
        isDarkMode ? 'trade-container-dark-mode' : 'trade-container'
      }`}
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <button className="btn regular-btn bold border-rad-05 back-to-vaults-btn">
        <Link
          to="/borrow"
          className="trade-card-analytics-link"
          style={{
            color: 'white',
            fontWeight: 'bold',
            fontSize: '1.175rem',
          }}
          type="button"
        >
          ↩ Pick Other Collateral
        </Link>
      </button>

      <div className="create-vault-container ">
        <div className="borrow-logos">
          <img
            src={dai}
            alt="DAI"
            width="70"
            height="70"
            style={{
              marginRight: '25px',
            }}
          />
          <img
            src={long}
            alt="LONG"
            width="70"
            height="70"
            className="no-cursor-pointer token-with-border"
            style={{
              backgroundColor: '#ffffff',
            }}
          />
          <span className="slash">/</span>
          <img src={eth} alt="ETH" width="70" height="70" />
        </div>

        <h1
          className="text-center bold mb-5 mt-4"
          style={{ fontSize: '2.75rem' }}
        >
          Borrow DAI Using LONG / WETH SLP as Collateral
        </h1>

        <h2 className="text-center bold create-vault-title">
          Approve LONG / WETH SLP
        </h2>

        <p className="text-center create-vault-subtitle">
          LONG / WETH SLP not approved yet. Please click on "Approve LONG / WETH
          SLP" to approve the LONG / WETH SLP token as collateral and start
          borrowing DAI stablecoin against it.
          <br /> <br /> <br /> <br />
          <span
            style={{
              fontSize: '1.5rem',
            }}
          >
            <span
              style={{
                textDecoration: 'underline',
                fontWeight: 'bold',
              }}
            >
              NOTE:
            </span>{' '}
            Make sure your wallet is connected and it's{' '}
            <Link
              to="/add-polygon"
              className="trade-card-analytics-link doc-link"
              style={{
                color: '#0066ff',
                fontWeight: 'bold',
              }}
            >
              on the Polygon (Matic) mainnet.
            </Link>
          </span>
        </p>
        <button
          className="btn regular-btn create-vault-btn"
          type="button"
          onClick={approveFn}
        >
          Approve LONG / WETH SLP
        </button>
      </div>
    </div>
  )
}

export default BorrowLONGWETH
