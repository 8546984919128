import React, { useContext, useEffect, useState } from 'react'
import { ethers } from 'ethers'
import NumberFormat from 'react-number-format'
import { ThemeContext } from '../../state/ThemeContext'
import matic from '../../assets/images/matic.png'
import eth from '../../assets/images/eth.png'
import btc from '../../assets/images/renBTC.png'
import dai from '../../assets/images/dai.png'
import mim from '../../assets/images/MIM.png'
import ust from '../../assets/images/UST.png'
import alts from '../../assets/images/ALTS_logo.png'
import long from '../../assets/images/LONG_logo.png'
import percentageIcon from '../../assets/images/ratio.png'
import totalStakedIcon from '../../assets/images/total-staked.png'
import fireIcon from '../../assets/images/fire.jpg'
import longtermJson from '../../contracts/longterm.json'
import uniV2Pair from '../../contracts/UniswapV2Pair.json'
import { RPC_URL, LONG_POOL, BURN_ADDRESS } from '../../utils/constants'
import './stats.css'

const Stats = () => {
  const { isDarkMode } = useContext(ThemeContext)

  const [altsSupply, setAltsSupply] = useState('0.00')
  const [altsPrice, setAltsPrice] = useState('0.00')
  const [altsMarketCap, setAltsMarketCap] = useState('0.00')
  const [longSupply, setLongSupply] = useState('0.00')
  const [longPrice, setLongPrice] = useState('0.00')
  const [longMarketCap, setLongMarketCap] = useState('0.00')
  const [totalLongBurned, setTotalLongBurned] = useState('0.00')
  const [globalTvl, setGlobalTvl] = useState('0.00')
  const [maticTvl, setMaticTvl] = useState('0.00')
  const [ethTvl, setEthTvl] = useState('0.00')
  const [btcTvl, setBtcTvl] = useState('0.00')
  const [daiTvl, setDaiTvl] = useState('0.00')
  const [mimTvl, setMimTvl] = useState('0.00')
  const [ustTvl, setUstTvl] = useState('0.00')
  const [totalMaticStake, setTotalMaticStake] = useState([])
  const [totalEthStake, setTotalEthStake] = useState([])
  const [totalBtcStake, setTotalBtcStake] = useState([])
  const [totalDaiStake, setTotalDaiStake] = useState([])
  const [totalMimStake, setTotalMimStake] = useState([])
  const [totalUstStake, setTotalUstStake] = useState([])
  const [globalCRatio, setGlobalCRatio] = useState('0.00')
  const [maticPercent, setMaticPercent] = useState('0.00')
  const [ethPercent, setEthPercent] = useState('0.00')
  const [btcPercent, setBtcPercent] = useState('0.00')
  const [daiPercent, setDaiPercent] = useState('0.00')
  const [mimPercent, setMimPercent] = useState('0.00')
  const [ustPercent, setUstPercent] = useState('0.00')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const loadData = async () => {
      const provider = new ethers.providers.JsonRpcProvider(RPC_URL)
      const contracts = longtermJson[137].matic.contracts

      // Set required contracts
      const MATICOracle = new ethers.Contract(
        contracts.MATICOracle.address,
        contracts.MATICOracle.abi,
        provider,
      )

      const ETHOracle = new ethers.Contract(
        contracts.ETHOracle.address,
        contracts.ETHOracle.abi,
        provider,
      )

      const BTCOracle = new ethers.Contract(
        contracts.BTCOracle.address,
        contracts.BTCOracle.abi,
        provider,
      )

      const DAIOracle = new ethers.Contract(
        contracts.DAIOracle.address,
        contracts.DAIOracle.abi,
        provider,
      )

      const MIMOracle = new ethers.Contract(
        contracts.MIMOracle.address,
        contracts.MIMOracle.abi,
        provider,
      )

      const USTOracle = new ethers.Contract(
        contracts.USTOracle.address,
        contracts.USTOracle.abi,
        provider,
      )

      const ALTSOracle = new ethers.Contract(
        contracts.ALTSOracle.address,
        contracts.ALTSOracle.abi,
        provider,
      )

      const MATICVault = new ethers.Contract(
        contracts.MATICVaultHandler.address,
        contracts.MATICVaultHandler.abi,
        provider,
      )

      const ETHVault = new ethers.Contract(
        contracts.ETHVaultHandler.address,
        contracts.ETHVaultHandler.abi,
        provider,
      )

      const BTCVault = new ethers.Contract(
        contracts.BTCVaultHandler.address,
        contracts.BTCVaultHandler.abi,
        provider,
      )

      const DAIVault = new ethers.Contract(
        contracts.DAIVaultHandler.address,
        contracts.DAIVaultHandler.abi,
        provider,
      )

      const MIMVault = new ethers.Contract(
        contracts.MIMVaultHandler.address,
        contracts.MIMVaultHandler.abi,
        provider,
      )

      const USTVault = new ethers.Contract(
        contracts.USTVaultHandler.address,
        contracts.USTVaultHandler.abi,
        provider,
      )

      const ALTSToken = new ethers.Contract(
        contracts.ALTS.address,
        contracts.ALTS.abi,
        provider,
      )

      const LONGToken = new ethers.Contract(
        contracts.Long.address,
        contracts.Long.abi,
        provider,
      )

      const LONGLP = new ethers.Contract(LONG_POOL, uniV2Pair.abi, provider)

      // Get oracle prices
      const maticUSDRes = await MATICOracle.getLatestAnswer()
      const maticUSD = ethers.utils.formatUnits(maticUSDRes.toString(), 8)

      const ethUSDRes = await ETHOracle.getLatestAnswer()
      const ethUSD = ethers.utils.formatUnits(ethUSDRes.toString(), 8)

      const btcUSDRes = await BTCOracle.getLatestAnswer()
      const btcUSD = ethers.utils.formatUnits(btcUSDRes.toString(), 8)

      const daiUSDRes = await DAIOracle.getLatestAnswer()
      const daiUSD = ethers.utils.formatUnits(daiUSDRes.toString(), 8)

      const mimUSDRes = await MIMOracle.getLatestAnswer()
      const mimUSD = ethers.utils.formatUnits(mimUSDRes.toString(), 8)

      const ustUSDRes = await USTOracle.getLatestAnswer()
      const ustUSD = ethers.utils.formatUnits(ustUSDRes.toString(), 8)

      const altsUSDRes = await ALTSOracle.getLatestAnswer()
      const altsUSD = ethers.utils.formatUnits(altsUSDRes.toString(), 8)

      // 1.) ALTS Total Supply
      const altsTotalSupplyRes = await ALTSToken.totalSupply()
      const altsTotalSupply = ethers.utils.formatEther(
        altsTotalSupplyRes.toString(),
      )
      setAltsSupply(altsTotalSupply)

      // 2.) ALTS Price
      setAltsPrice(altsUSD)

      // 3.) ALTS Market Cap
      const currentAltsMarketCap = parseFloat(altsTotalSupply * altsUSD)
      setAltsMarketCap(currentAltsMarketCap)

      // 4.) LONG Max Supply
      const longMaxSupplyRes = await LONGToken.totalSupply()
      const longMaxSupply = ethers.utils.formatEther(
        longMaxSupplyRes.toString(),
      )
      setLongSupply(longMaxSupply)

      // 5.) LONG Price
      const longLPData = await LONGLP.getReserves()
      const longUSD =
        ethUSD /
        parseFloat(
          ethers.utils.formatEther(
            ethers.utils.parseEther('1').mul(longLPData[0]).div(longLPData[1]),
          ),
        )
      setLongPrice(longUSD)

      // 6.) LONG Market Cap (Fully Diluted)
      const currentLongMarketCap = parseFloat(longMaxSupply * longUSD)
      setLongMarketCap(currentLongMarketCap)

      // 7.) Total LONG Burned
      const currentTotalLongBurnedRes = await LONGToken.balanceOf(BURN_ADDRESS)
      const currentTotalLongBurned = ethers.utils.formatEther(
        currentTotalLongBurnedRes.toString(),
      )
      setTotalLongBurned(currentTotalLongBurned)

      // 9.) TVL in MATIC
      const currentCounterMatic = await MATICVault.counter()
      const currentCounterIntMatic = parseInt(
        ethers.utils.formatEther(
          ethers.utils.parseEther(currentCounterMatic.toString()).toString(),
        ),
      )

      for (let c = 1; c <= currentCounterIntMatic - 1; c++) {
        let currentVault = await MATICVault.vaults(c)

        let currentVaultCollateral = currentVault[1].toString()

        let currentVaultCollateralParsed = parseFloat(
          ethers.utils.formatEther(currentVaultCollateral),
        )

        totalMaticStake.push(currentVaultCollateralParsed)
      }

      setTotalMaticStake(totalMaticStake)
      const slicedTotalMaticStake = totalMaticStake.slice(
        0,
        currentCounterIntMatic - 1,
      )

      const currentMaticTvl = slicedTotalMaticStake.reduce((a, c) => a + c, 0)
      setMaticTvl(currentMaticTvl)

      const currentMaticTvlUsd = parseFloat(currentMaticTvl * maticUSD)

      // 10.) TVL in WETH
      const currentCounter = await ETHVault.counter()
      const currentCounterInt = parseInt(
        ethers.utils.formatEther(
          ethers.utils.parseEther(currentCounter.toString()).toString(),
        ),
      )

      for (let c = 1; c <= currentCounterInt - 1; c++) {
        let currentVault = await ETHVault.vaults(c)

        let currentVaultCollateral = currentVault[1].toString()

        let currentVaultCollateralParsed = parseFloat(
          ethers.utils.formatEther(currentVaultCollateral),
        )

        totalEthStake.push(currentVaultCollateralParsed)
      }

      setTotalEthStake(totalEthStake)
      const slicedTotalEthStake = totalEthStake.slice(0, currentCounterInt - 1)

      const currentEthTvl = slicedTotalEthStake.reduce((a, c) => a + c, 0)
      setEthTvl(currentEthTvl)

      const currentEthTvlUsd = parseFloat(currentEthTvl * ethUSD)

      // 11.) TVL in renBTC
      const currentCounterBtc = await BTCVault.counter()
      const currentCounterIntBtc = parseInt(
        ethers.utils.formatEther(
          ethers.utils.parseEther(currentCounterBtc.toString()).toString(),
        ),
      )

      for (let c = 1; c <= currentCounterIntBtc - 1; c++) {
        let currentVault = await BTCVault.vaults(c)

        let currentVaultCollateral = currentVault[1].toString()

        let currentVaultCollateralParsed = parseFloat(
          ethers.utils.formatEther(currentVaultCollateral),
        )

        totalBtcStake.push(currentVaultCollateralParsed)
      }

      setTotalBtcStake(totalBtcStake)
      const slicedTotalBtcStake = totalBtcStake.slice(
        0,
        currentCounterIntBtc - 1,
      )

      const currentBtcTvl = slicedTotalBtcStake.reduce((a, c) => a + c, 0)
      setBtcTvl(currentBtcTvl)

      const currentBtcTvlUsd = parseFloat(currentBtcTvl * btcUSD)

      // 12.) TVL in DAI
      const currentCounterDai = await DAIVault.counter()
      const currentCounterIntDai = parseInt(
        ethers.utils.formatEther(
          ethers.utils.parseEther(currentCounterDai.toString()).toString(),
        ),
      )

      for (let c = 1; c <= currentCounterIntDai - 1; c++) {
        let currentVault = await DAIVault.vaults(c)

        let currentVaultCollateral = currentVault[1].toString()

        let currentVaultCollateralParsed = parseFloat(
          ethers.utils.formatEther(currentVaultCollateral),
        )

        totalDaiStake.push(currentVaultCollateralParsed)
      }

      setTotalDaiStake(totalDaiStake)
      const slicedTotalDaiStake = totalDaiStake.slice(
        0,
        currentCounterIntDai - 1,
      )

      const currentDaiTvl = slicedTotalDaiStake.reduce((a, c) => a + c, 0)
      setDaiTvl(currentDaiTvl)

      const currentDaiTvlUsd = parseFloat(currentDaiTvl * daiUSD)

      // 13.) TVL in MIM
      const currentCounterMim = await MIMVault.counter()
      const currentCounterIntMim = parseInt(
        ethers.utils.formatEther(
          ethers.utils.parseEther(currentCounterMim.toString()).toString(),
        ),
      )

      for (let c = 1; c <= currentCounterIntMim - 1; c++) {
        let currentVault = await MIMVault.vaults(c)

        let currentVaultCollateral = currentVault[1].toString()

        let currentVaultCollateralParsed = parseFloat(
          ethers.utils.formatEther(currentVaultCollateral),
        )

        totalMimStake.push(currentVaultCollateralParsed)
      }

      setTotalMimStake(totalMimStake)
      const slicedTotalMimStake = totalMimStake.slice(
        0,
        currentCounterIntMim - 1,
      )

      const currentMimTvl = slicedTotalMimStake.reduce((a, c) => a + c, 0)
      setMimTvl(currentMimTvl)

      const currentMimTvlUsd = parseFloat(currentMimTvl * mimUSD)

      // 14.) TVL in UST
      const currentCounterUst = await USTVault.counter()
      const currentCounterIntUst = parseInt(
        ethers.utils.formatEther(
          ethers.utils.parseEther(currentCounterUst.toString()).toString(),
        ),
      )

      for (let c = 1; c <= currentCounterIntUst - 1; c++) {
        let currentVault = await USTVault.vaults(c)

        let currentVaultCollateral = currentVault[1].toString()

        let currentVaultCollateralParsed = parseFloat(
          ethers.utils.formatEther(currentVaultCollateral),
        )

        totalUstStake.push(currentVaultCollateralParsed)
      }

      setTotalUstStake(totalUstStake)
      const slicedTotalUstStake = totalUstStake.slice(
        0,
        currentCounterIntUst - 1,
      )

      const currentUstTvl = slicedTotalUstStake.reduce((a, c) => a + c, 0)
      setUstTvl(currentUstTvl)

      const currentUstTvlUsd = parseFloat(currentUstTvl * ustUSD)

      // 8.) Total Value Locked (TVL)
      const currentGlobalTvlUsd =
        currentMaticTvlUsd +
        currentEthTvlUsd +
        currentBtcTvlUsd +
        currentDaiTvlUsd +
        currentMimTvlUsd +
        currentUstTvlUsd
      setGlobalTvl(currentGlobalTvlUsd)

      // 15.) Global Collateral Ratio
      const globalDebt = altsTotalSupply * altsUSD
      const currentGlobalCRatio = (currentGlobalTvlUsd / globalDebt) * 100
      setGlobalCRatio(currentGlobalCRatio)

      // 16.) MATIC as a % of Collateral
      const currentMaticPercent =
        (currentMaticTvlUsd / currentGlobalTvlUsd) * 100
      setMaticPercent(currentMaticPercent)

      // 17.) WETH as a % of Collateral
      const currentEthPercent = (currentEthTvlUsd / currentGlobalTvlUsd) * 100
      setEthPercent(currentEthPercent)

      // 18.) renBTC as a % of Collateral
      const currentBtcPercent = (currentBtcTvlUsd / currentGlobalTvlUsd) * 100
      setBtcPercent(currentBtcPercent)

      // 19.) DAI as a % of Collateral
      const currentDaiPercent = (currentDaiTvlUsd / currentGlobalTvlUsd) * 100
      setDaiPercent(currentDaiPercent)

      // 20.) MIM as % of Collateral
      const currentMimPercent = (currentMimTvlUsd / currentGlobalTvlUsd) * 100
      setMimPercent(currentMimPercent)

      // 21.) UST as % of Collateral
      const currentUstPercent = (currentUstTvlUsd / currentGlobalTvlUsd) * 100
      setUstPercent(currentUstPercent)

      setIsLoading(false)
    }

    loadData()
  }, [
    totalMaticStake,
    totalEthStake,
    totalBtcStake,
    totalDaiStake,
    totalMimStake,
    totalUstStake,
    altsSupply,
    altsPrice,
    altsMarketCap,
    longSupply,
    longPrice,
    longMarketCap,
    totalLongBurned,
    globalTvl,
    maticTvl,
    ethTvl,
    btcTvl,
    daiTvl,
    mimTvl,
    ustTvl,
    globalCRatio,
    maticPercent,
    ethPercent,
    btcPercent,
    daiPercent,
    mimPercent,
    ustPercent,
  ])

  const StatsCard = ({ statIcon, statName, statData, hasBorder, whiteBg }) => (
    <div
      className={`${
        !isDarkMode
          ? 'trade-card-container-new'
          : 'trade-card-container-dark-mode-new'
      } stats-card`}
    >
      <img
        src={statIcon}
        alt={statName}
        width="60"
        height="60"
        className="trade-icon-2"
        style={{
          border: hasBorder ? '2px solid #0066ff' : 'none',
          backgroundColor: whiteBg && '#ffffff',
        }}
      />

      <h2
        className="trade-card-title text-center"
        style={{ marginBottom: '0.5rem' }}
      >
        {statName}
      </h2>
      <h3 className="trade-card-dex">{statData}</h3>
    </div>
  )

  return (
    <div
      className={`stats-container ${
        isDarkMode ? 'trade-container-dark-mode' : 'trade-container'
      }`}
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <h1
        className="text-center bold mb-5 mt-5"
        style={{ fontSize: '2.75rem' }}
      >
        LongTerm Finance Stats
      </h1>

      <span
        className="text-center"
        style={{
          display: 'block',
          fontSize: '2rem',
          fontWeight: 'bold',
        }}
      >
        {isLoading && <>(Loading Stats...)</>}
      </span>

      <br />
      <br />

      <div className="trade-grid">
        <StatsCard
          statIcon={alts}
          hasBorder
          whiteBg
          statName={'ALTS Total Supply'}
          statData={
            <>
              <NumberFormat
                className="number"
                value={!isLoading ? altsSupply : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />{' '}
              ALTS
            </>
          }
        />

        <StatsCard
          statIcon={alts}
          hasBorder
          whiteBg
          statName={'ALTS Price'}
          statData={
            <>
              $
              <NumberFormat
                className="number"
                value={!isLoading ? altsPrice : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />
            </>
          }
        />

        <StatsCard
          statIcon={alts}
          hasBorder
          whiteBg
          statName={'ALTS Market Cap'}
          statData={
            <>
              $
              <NumberFormat
                className="number"
                value={!isLoading ? altsMarketCap : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />
            </>
          }
        />

        <StatsCard
          statIcon={long}
          hasBorder
          whiteBg
          statName={'LONG Max Supply'}
          statData={
            <>
              <NumberFormat
                className="number"
                value={!isLoading ? longSupply : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />{' '}
              LONG
            </>
          }
        />

        <StatsCard
          statIcon={long}
          hasBorder
          whiteBg
          statName={'LONG Price'}
          statData={
            <>
              $
              <NumberFormat
                className="number"
                value={!isLoading ? longPrice : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={5}
              />
            </>
          }
        />

        <StatsCard
          statIcon={long}
          hasBorder
          whiteBg
          statName={'LONG Market Cap (Fully Diluted)'}
          statData={
            <>
              $
              <NumberFormat
                className="number"
                value={!isLoading ? longMarketCap : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />
            </>
          }
        />

        <StatsCard
          statIcon={fireIcon}
          whiteBg
          statName={'Total LONG Burned'}
          statData={
            <>
              <NumberFormat
                className="number"
                value={!isLoading ? totalLongBurned : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />{' '}
              LONG
            </>
          }
        />

        <StatsCard
          statIcon={totalStakedIcon}
          whiteBg
          statName={'Total Value Locked (TVL)'}
          statData={
            <>
              $
              <NumberFormat
                className="number"
                value={!isLoading ? globalTvl : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />
            </>
          }
        />

        <StatsCard
          statIcon={matic}
          statName={'TVL in MATIC'}
          statData={
            <>
              <NumberFormat
                className="number"
                value={!isLoading ? maticTvl : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />{' '}
              MATIC
            </>
          }
        />

        <StatsCard
          statIcon={eth}
          statName={'TVL in WETH'}
          statData={
            <>
              <NumberFormat
                className="number"
                value={!isLoading ? ethTvl : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />{' '}
              WETH
            </>
          }
        />

        <StatsCard
          statIcon={btc}
          statName={'TVL in renBTC'}
          statData={
            <>
              <NumberFormat
                className="number"
                value={!isLoading ? btcTvl : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />{' '}
              renBTC
            </>
          }
        />

        <StatsCard
          statIcon={dai}
          statName={'TVL in DAI'}
          statData={
            <>
              <NumberFormat
                className="number"
                value={!isLoading ? daiTvl : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />{' '}
              DAI
            </>
          }
        />

        <StatsCard
          statIcon={mim}
          statName={'TVL in MIM'}
          statData={
            <>
              <NumberFormat
                className="number"
                value={!isLoading ? mimTvl : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />{' '}
              MIM
            </>
          }
        />

        <StatsCard
          statIcon={ust}
          statName={'TVL in UST'}
          statData={
            <>
              <NumberFormat
                className="number"
                value={!isLoading ? ustTvl : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />{' '}
              UST
            </>
          }
        />

        <StatsCard
          statIcon={percentageIcon}
          whiteBg
          statName={'Global Collateral Ratio'}
          statData={
            <>
              <NumberFormat
                className="number"
                value={!isLoading ? globalCRatio : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />{' '}
              %
            </>
          }
        />

        <StatsCard
          statIcon={matic}
          statName={'MATIC as a % of Collateral'}
          statData={
            <>
              <NumberFormat
                className="number"
                value={!isLoading ? maticPercent : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />{' '}
              %
            </>
          }
        />

        <StatsCard
          statIcon={eth}
          statName={'WETH as a % of Collateral'}
          statData={
            <>
              <NumberFormat
                className="number"
                value={!isLoading ? ethPercent : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />{' '}
              %
            </>
          }
        />

        <StatsCard
          statIcon={btc}
          statName={'renBTC as a % of Collateral'}
          statData={
            <>
              <NumberFormat
                className="number"
                value={!isLoading ? btcPercent : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />{' '}
              %
            </>
          }
        />

        <StatsCard
          statIcon={dai}
          statName={'DAI as a % of Collateral'}
          statData={
            <>
              <NumberFormat
                className="number"
                value={!isLoading ? daiPercent : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />{' '}
              %
            </>
          }
        />

        <StatsCard
          statIcon={mim}
          statName={'MIM as a % of Collateral'}
          statData={
            <>
              <NumberFormat
                className="number"
                value={!isLoading ? mimPercent : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />{' '}
              %
            </>
          }
        />

        <StatsCard
          statIcon={ust}
          statName={'UST as a % of Collateral'}
          statData={
            <>
              <NumberFormat
                className="number"
                value={!isLoading ? ustPercent : '0.00'}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={2}
              />{' '}
              %
            </>
          }
        />
      </div>
    </div>
  )
}

export default Stats
