import React, { useContext, useState, useEffect } from 'react'
import './farm.css'
import long from '../../assets/images/LONG_logo.png'
import alts from '../../assets/images/ALTS_logo.png'
import eth from '../../assets/images/eth.png'
import SignerContext from '../../state/SignerContext'
import { ThemeContext } from '../../state/ThemeContext'
import { ethers, BigNumber } from 'ethers'
import longtermJson from '../../contracts/longterm.json'
import uniV2Pair from '../../contracts/UniswapV2Pair.json'
import NumberFormat from 'react-number-format'
import {
  RPC_URL,
  LONG_POOL,
  ALTS_POOL,
  ZERO_ADDRESS,
  CHAIN_ID,
} from '../../utils/constants'
import { Modal } from 'semantic-ui-react'
import {
  errorNotification,
  notifyUser,
  getPriceInUSDFromPair,
} from '../../utils/utils'
import { Link } from 'react-router-dom'

const Farm = () => {
  const VESTING_RATIO = 80
  const oneYear = 60 * 60 * 24 * 365

  const { isDarkMode } = useContext(ThemeContext)
  const signer = useContext(SignerContext)

  const [address, setAddress] = useState(ZERO_ADDRESS)
  const [isLoading, setIsLoading] = useState(true)
  const [stakeShow, setStakeShow] = useState(false)
  const [networkWarning, setNetworkWarning] = useState(true)
  const [poolWarningOpen, setPoolWarningOpen] = useState(true)
  const [chainId, setChainId] = useState(1)

  const [altsPoolBalance, setAltsPoolBalance] = useState('0.00')
  const [longPoolBalance, setLongPoolBalance] = useState('0.00')
  const [altsPoolStakedBalance, setAltsPoolStakedBalance] = useState('0.00')
  const [longPoolStakedBalance, setLongPoolStakedBalance] = useState('0.00')
  const [altsPoolUnlockedReward, setAltsPoolUnlockedReward] = useState('0.00')
  const [longPoolUnlockedReward, setLongPoolUnlockedReward] = useState('0.00')
  const [altsPoolLockedReward, setAltsPoolLockedReward] = useState('0.00')
  const [longPoolLockedReward, setLongPoolLockedReward] = useState('0.00')
  const [altsPoolEstimatedAPY, setAltsPoolEstimatedAPY] = useState('0.00')
  const [longPoolEstimatedAPY, setLongPoolEstimatedAPY] = useState('0.00')

  const [selectedPool, setSelectedPool] = useState('')
  const [selectedPoolToken, setSelectedPoolToken] = useState('')
  const [selectedPoolTitle, setSelectedPoolTitle] = useState('')
  const [stakeBalance, setStakeBalance] = useState('')

  const [altsLiquidityReward, setAltsLiquidityReward] = useState('')
  const [longLiquidityReward, setLongLiquidityReward] = useState('')
  const [altsPoolToken, setAltsPoolToken] = useState('')
  const [longPoolToken, setLongPoolToken] = useState('')

  useEffect(() => {
    const loadData = async () => {
      const provider = new ethers.providers.JsonRpcProvider(RPC_URL)
      const contracts = longtermJson[137].matic.contracts

      // Set address
      if (signer.signer) {
        const currentAddress = await signer.signer.getAddress()
        setAddress(currentAddress)

        const currentChainId = await signer.signer.getChainId()
        setChainId(parseInt(currentChainId))

        // Set required contracts
        const ETHOracle = new ethers.Contract(
          contracts.ETHOracle.address,
          contracts.ETHOracle.abi,
          provider,
        )

        const LONGLP = new ethers.Contract(LONG_POOL, uniV2Pair.abi, provider)

        const ALTSLP = new ethers.Contract(ALTS_POOL, uniV2Pair.abi, provider)

        const LONGLPSigner = new ethers.Contract(
          LONG_POOL,
          uniV2Pair.abi,
          signer.signer,
        )
        setLongPoolToken(LONGLPSigner)

        const ALTSLPSigner = new ethers.Contract(
          ALTS_POOL,
          uniV2Pair.abi,
          signer.signer,
        )
        setAltsPoolToken(ALTSLPSigner)

        const longLiquidityRewardRead = new ethers.Contract(
          contracts.LONGLiquidityReward.address,
          contracts.LONGLiquidityReward.abi,
          provider,
        )

        const altsLiquidityRewardRead = new ethers.Contract(
          contracts.ALTSLiquidityReward.address,
          contracts.ALTSLiquidityReward.abi,
          provider,
        )

        const LONGLiquidityReward = new ethers.Contract(
          contracts.LONGLiquidityReward.address,
          contracts.LONGLiquidityReward.abi,
          signer.signer,
        )
        setLongLiquidityReward(LONGLiquidityReward)

        const ALTSLiquidityReward = new ethers.Contract(
          contracts.ALTSLiquidityReward.address,
          contracts.ALTSLiquidityReward.abi,
          signer.signer,
        )
        setAltsLiquidityReward(ALTSLiquidityReward)

        // LP Token Balances
        const currentLongPoolBalance = await LONGLP.balanceOf(currentAddress)
        setLongPoolBalance(
          ethers.utils.formatEther(currentLongPoolBalance.toString()),
        )

        const currentAltsPoolBalance = await ALTSLP.balanceOf(currentAddress)
        setAltsPoolBalance(
          ethers.utils.formatEther(currentAltsPoolBalance.toString()),
        )

        // Staked LP Token Balances
        const currentAltsPoolStakedBalance = await altsLiquidityRewardRead.balanceOf(
          currentAddress,
        )
        setAltsPoolStakedBalance(
          ethers.utils.formatEther(currentAltsPoolStakedBalance.toString()),
        )

        const currentLongPoolStakedBalance = await longLiquidityRewardRead.balanceOf(
          currentAddress,
        )
        setLongPoolStakedBalance(
          ethers.utils.formatEther(currentLongPoolStakedBalance.toString()),
        )

        // Set Unlocked Rewards
        const currentAltsPoolUnlockedReward = await altsLiquidityRewardRead.earned(
          currentAddress,
        )
        setAltsPoolUnlockedReward(
          ethers.utils.formatEther(
            currentAltsPoolUnlockedReward.mul(100 - VESTING_RATIO).div(100),
          ),
        )

        const currentLongPoolUnlockedReward = await longLiquidityRewardRead.earned(
          currentAddress,
        )
        setLongPoolUnlockedReward(
          ethers.utils.formatEther(
            currentLongPoolUnlockedReward.mul(100 - VESTING_RATIO).div(100),
          ),
        )

        // Set Locked Rewards
        const currentAltsPoolLockedReward = await altsLiquidityRewardRead.vestingAmounts(
          currentAddress,
        )
        setAltsPoolLockedReward(
          ethers.utils.formatEther(
            currentAltsPoolLockedReward.add(
              currentAltsPoolUnlockedReward.mul(VESTING_RATIO).div(100),
            ),
          ),
        )

        const currentLongPoolLockedReward = await longLiquidityRewardRead.vestingAmounts(
          currentAddress,
        )
        setLongPoolLockedReward(
          ethers.utils.formatEther(
            currentLongPoolLockedReward.add(
              currentLongPoolUnlockedReward.mul(VESTING_RATIO).div(100),
            ),
          ),
        )

        // Set Estimated APYs
        async function getEstimatedAPY(
          rate,
          LPsStaked,
          reserves,
          totalSupplyPool,
          longPrice,
          ethPrice,
        ) {
          const token0Price = await getPriceInUSDFromPair(
            reserves[0],
            reserves[1],
            ethPrice,
          )

          const valuePerLPToken =
            (token0Price * reserves[0] + ethPrice * reserves[1]) /
            totalSupplyPool

          const apy =
            ((rate * oneYear * longPrice) / (valuePerLPToken * LPsStaked)) * 100

          if (Number.isNaN(apy)) {
            return '0.0'
          }

          return apy.toString()
        }

        const ethUSDRes = await ETHOracle.getLatestAnswer()
        const ethUSD = ethers.utils.formatUnits(ethUSDRes.toString(), 8)

        const longLPData = await LONGLP.getReserves()
        const currentLONGPrice =
          ethUSD /
          parseFloat(
            ethers.utils.formatEther(
              ethers.utils
                .parseEther('1')
                .mul(longLPData[0])
                .div(longLPData[1]),
            ),
          )

        const rateAltsPool = await altsLiquidityRewardRead.rewardRate()
        const altsLPsStaked = await altsLiquidityRewardRead.totalSupply()
        const reservesAltsPool = await ALTSLP.getReserves()
        const totalSupplyAltsPool = await ALTSLP.totalSupply()
        setAltsPoolEstimatedAPY(
          await getEstimatedAPY(
            rateAltsPool,
            altsLPsStaked,
            reservesAltsPool,
            totalSupplyAltsPool,
            currentLONGPrice,
            parseFloat(ethUSD),
          ),
        )

        const rateLongPool = await longLiquidityRewardRead.rewardRate()
        const longLPsStaked = await longLiquidityRewardRead.totalSupply()
        const reservesLongPool = await LONGLP.getReserves()
        const totalSupplyLongPool = await LONGLP.totalSupply()
        setLongPoolEstimatedAPY(
          await getEstimatedAPY(
            rateLongPool,
            longLPsStaked,
            reservesLongPool,
            totalSupplyLongPool,
            currentLONGPrice,
            parseFloat(ethUSD),
          ),
        )

        setIsLoading(false)
      }
    }

    loadData()
  }, [address, signer.signer, altsPoolBalance, longPoolBalance, oneYear])

  // Components
  const Stake = ({
    show,
    poolTitle,
    poolToken,
    liquidityReward,
    balance,
    onHide,
    refresh,
  }) => {
    const [stakeAmount, setStakeAmount] = useState('')
    const [isApproved, setIsApproved] = useState(false)
    const signer = useContext(SignerContext)

    // Infinite Approve SLP Token for Staking
    const approveValue = BigNumber.from(
      '1157920892373161954235709850086879078532699',
    )

    if (poolTitle.includes('ALTS')) {
      liquidityReward = altsLiquidityReward
    } else if (poolTitle.includes('LONG')) {
      liquidityReward = longLiquidityReward
    }

    useEffect(() => {
      async function loadData() {
        if (poolToken && signer.signer && liquidityReward) {
          const currentAddress = await signer.signer.getAddress()
          const approved = await poolToken.allowance(
            currentAddress,
            liquidityReward.address,
          )

          if (approved.toString() !== '0') {
            setIsApproved(true)
          } else {
            setIsApproved(false)
          }
        }
      }
      loadData()
      // eslint-disable-next-line
    }, [poolToken, signer.signer, liquidityReward?.address])

    const onChangeStake = (e) => {
      setStakeAmount(e.target.value)
    }

    const stakeTokens = async (e) => {
      e.preventDefault()

      if (liquidityReward) {
        if (stakeAmount !== '') {
          try {
            const tx = await liquidityReward.stake(
              ethers.utils.parseEther(stakeAmount),
            )
            notifyUser(tx, refresh)
            setStakeAmount('')
            onHide()
          } catch (error) {
            if (error.code === 4001) {
              errorNotification('User rejected transaction signature.')
            } else {
              errorNotification('Token not approved.')
            }
          }
        } else {
          errorNotification("Field can't be empty.")
        }
      }
    }

    const approveTokens = async (e) => {
      e.preventDefault()

      if (poolToken) {
        try {
          const tx = await poolToken.approve(
            liquidityReward.address,
            approveValue,
          )
          notifyUser(tx, refresh)
          setStakeAmount('')
          setIsApproved(true)
        } catch (error) {
          if (error.code === 4001) {
            errorNotification('User rejected transaction signature.')
          } else {
            console.log(error)
          }
        }
      }
    }

    const maxStake = async (e) => {
      e.preventDefault()
      setStakeAmount(balance)
    }

    return (
      <Modal
        open={show}
        size="tiny"
        onClose={() => {
          setStakeAmount('')
          onHide()
        }}
        className={isDarkMode ? 'stake-modal-dark-mode' : 'stake-modal'}
        style={{
          borderRadius: '10px',
        }}
      >
        <header>
          <h1 className="stake-title">Stake SushiSwap {poolTitle}</h1>
        </header>
        <div>
          <br />
          <br />
          <p className="stake-balance">
            Available Balance:{' '}
            <strong>
              <NumberFormat
                className="number"
                value={balance}
                displayType="text"
                thousandSeparator
                prefix=""
                decimalScale={4}
              />{' '}
              SLP
            </strong>
          </p>
          <br />
          <form>
            <div className="form-group">
              {isApproved ? (
                <>
                  <div className="input-labels-container">
                    <label className="form-label bold input-title">
                      Stake Tokens
                    </label>
                    <label className="max form-label">
                      <a
                        href="/"
                        className="number bold max-text"
                        onClick={maxStake}
                      >
                        MAX
                      </a>
                    </label>
                  </div>
                  <div className="input-group">
                    <input
                      type="number"
                      name="stakeTokens"
                      placeholder="0"
                      className="form-control vault-input"
                      value={stakeAmount}
                      onChange={onChangeStake}
                    />
                    <div className="input-group-append">
                      <button
                        className="btn regular-btn bold vault-btn"
                        style={{ fontWeight: 'bold' }}
                        type="button"
                        onClick={stakeTokens}
                      >
                        Stake
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className="approve-btn-container">
                  <button
                    type="button"
                    className="btn regular-btn border-rad-05 mr-3 bold"
                    style={{ fontWeight: 'bold', textAlign: 'center' }}
                    onClick={approveTokens}
                  >
                    Approve Tokens
                  </button>
                </div>
              )}
            </div>
          </form>
        </div>
      </Modal>
    )
  }

  const MiningCard = ({
    icon1,
    icon2,
    tradingPair,
    dex,
    dexLink,
    addLiquidityLink,
    lpTokenBalance,
    stakedBalance,
    unlockedReward,
    lockedReward,
    estimatedAPY,
    rewardsAmount,
    stakeFn,
    claimFn,
    exitFn,
  }) => {
    return (
      <div
        className={`${
          !isDarkMode
            ? 'trade-card-container mining-card-container'
            : 'trade-card-container-dark-mode mining-card-container'
        }`}
      >
        <div className="trade-card-icons">
          <img
            src={icon1}
            width="50"
            height="50"
            alt={tradingPair && tradingPair.split('/')[0]}
            className="trade-icon-1"
            style={{
              backgroundColor: '#ffffff',
            }}
          />
          <img
            src={icon2}
            width="50"
            height="50"
            alt={tradingPair && tradingPair.split('/')[1]}
            className="trade-icon-2"
            style={{
              border: 'none',
            }}
          />
        </div>
        <h3 className="trade-card-title">
          <a
            target="_blank"
            rel="noreferrer"
            className="trade-card-analytics-link farm-link"
            style={{ fontWeight: 'bold', color: isDarkMode ? '#fff' : '#000' }}
            href={`${addLiquidityLink}`}
          >
            {tradingPair + ' Pool'}
          </a>
        </h3>
        <br />
        <p className="trade-card-dex">
          <strong>DEX: </strong>
          <a
            target="_blank"
            rel="noreferrer"
            className="trade-card-analytics-link farm-link"
            style={{ fontWeight: 'bold', color: '#0066ff' }}
            href={dexLink}
          >
            {dex}
          </a>
        </p>
        <p className="trade-card-trading-volume">
          <strong>Rewards Start:</strong> March 6th, 2022
        </p>
        <p className="trade-card-trading-volume">
          <strong>Rewards Duration:</strong> 3 years
        </p>
        <p className="trade-card-trading-volume">
          <strong>Total Rewards:</strong> {rewardsAmount} LONG
        </p>
        <p className="trade-card-trading-volume">
          <strong>Balance:</strong> {lpTokenBalance} SLP
        </p>
        <p className="trade-card-trading-volume">
          <strong>Staked:</strong> {stakedBalance} SLP
        </p>
        <p className="trade-card-trading-volume">
          <strong>Unlocked Reward:</strong> {unlockedReward} LONG
        </p>
        <p className="trade-card-trading-volume">
          <strong>Locked Reward:</strong> {lockedReward} LONG
        </p>
        <p className="trade-card-trading-volume">
          <strong>Locked Until:</strong> Jun 27th, 2022
        </p>
        <p className="trade-card-trading-volume">
          <strong>Current APY:</strong>{' '}
          <span
            style={{
              fontWeight: 'bold',
              color: '#0066ff',
            }}
          >
            {estimatedAPY} %
          </span>
        </p>

        <br />

        <div className="trading-buttons">
          <button
            className="btn regular-btn border-rad-05 mr-3"
            type="button"
            style={{ fontWeight: 'bold' }}
            onClick={stakeFn}
          >
            Stake
          </button>
          <button
            className="btn regular-btn border-rad-05 mr-3"
            type="button"
            style={{ fontWeight: 'bold' }}
            onClick={claimFn}
          >
            Claim
          </button>
          <button
            className="btn regular-btn border-rad-05 mr-3"
            type="button"
            style={{ fontWeight: 'bold' }}
            onClick={exitFn}
          >
            Exit
          </button>
        </div>
      </div>
    )
  }

  // Utilities
  const refresh = () => {
    window.location.reload()
  }

  const handleNetworkWarning = () => setNetworkWarning(false)

  const handlePoolWarning = () => setPoolWarningOpen(false)

  // Actions
  const claimRewards = async (vaultType) => {
    try {
      let tx

      switch (vaultType) {
        case 'ALTS':
          tx = await altsLiquidityReward.getReward()
          break
        case 'LONG':
          tx = await longLiquidityReward.getReward()
          break
        default:
          tx = await altsLiquidityReward.getReward()
          break
      }

      notifyUser(tx, refresh)
    } catch (error) {
      if (error.code === 4001) {
        errorNotification('User rejected transaction signature.')
      } else {
        errorNotification('Insufficient funds to stake.')
      }
    }
  }

  const exitRewards = async (vaultType) => {
    try {
      let tx

      switch (vaultType) {
        case 'ALTS':
          tx = await altsLiquidityReward.exit()
          break
        case 'LONG':
          tx = await longLiquidityReward.exit()
          break
        default:
          tx = await altsLiquidityReward.exit()
          break
      }

      notifyUser(tx, refresh)
    } catch (error) {
      if (error.code === 4001) {
        errorNotification('User rejected transaction signature.')
      } else {
        errorNotification('Insufficient funds to exit.')
      }
    }
  }

  return (
    <div
      className={`farms-container ${
        !isDarkMode ? 'trade-container' : 'trade-container-dark-mode'
      } mb-5`}
    >
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />

      <h1
        className="text-center bold mb-5 mt-5"
        style={{ fontSize: '2.75rem' }}
      >
        Incentivized Farms
      </h1>

      <p
        className={`text-mute text-center ml-5 mr-5 mb-5 ${
          isDarkMode ? 'early-adopter-note-dark-mode' : 'early-adopter-note'
        }`}
      >
        {' '}
        You can <strong>
          earn LongTerm Finance (LONG) governance tokens
        </strong>{' '}
        by staking your SushiSwap LP (SLP) tokens for any of the pairs listed
        below. <br />
        <strong>Unlocked Rewards</strong> (20% of total rewards) are available
        to claim immediately. <strong>Locked Rewards</strong> (80% of total
        rewards) are unlocked 6 months after the start of the pool.
        <br />
        To learn more about{' '}
        <strong>how LONG token rewards are distributed,</strong> as well as the
        overall tokenomics, check out{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://docs.long-term.finance/governance/long-token"
          style={{ color: '#0066ff' }}
          className="doc-link"
        >
          <strong>our official documentation.</strong>
        </a>
      </p>

      <br />

      {poolWarningOpen && (
        <div
          className={`${
            isDarkMode ? 'c-ratio-alert-dark-mode' : 'c-ratio-alert'
          }`}
          style={{
            fontSize: '1.1em',
          }}
        >
          <span>
            <strong>⚠️ Note:</strong> When adding liquidity to the ALTS / WETH
            pool, <strong>please use WETH from your wallet</strong> instead of
            swapping half of your newly minted ALTS tokens for WETH.
          </span>
          <span className="dismiss-alert" onClick={handlePoolWarning}>
            X
          </span>
        </div>
      )}

      {signer.signer && chainId !== CHAIN_ID && networkWarning && (
        <div
          className={`${
            isDarkMode ? 'c-ratio-alert-dark-mode' : 'c-ratio-alert'
          }`}
        >
          <strong>
            ⚠️ Please double check that your wallet is connected to the{' '}
            <Link
              to="/add-polygon"
              className="trade-card-analytics-link doc-link"
              style={{
                color: '#0066ff',
              }}
            >
              Polygon (Matic) mainnet.
            </Link>
          </strong>
          <span className="dismiss-alert" onClick={handleNetworkWarning}>
            X
          </span>
        </div>
      )}

      <span
        className="text-center"
        style={{
          display: 'block',
          fontSize: '2rem',
          fontWeight: 'bold',
        }}
      >
        {signer.signer && isLoading && (
          <>
            (Loading Balances...)
            <br />
            <br />
            <br />
          </>
        )}
      </span>

      <div className="trade-grid">
        <MiningCard
          addLiquidityLink={`https://app.sushi.com/add/0x63aD78D8007030130bB5b40E48B0FA2Cc700f294/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619`}
          icon1={alts}
          icon2={eth}
          tradingPair="ALTS / WETH"
          dex="SushiSwap"
          dexLink="https://app.sushi.com/add/0x63aD78D8007030130bB5b40E48B0FA2Cc700f294/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619"
          rewardsAmount={'2.5 million'}
          lpTokenBalance={
            <NumberFormat
              className="number"
              value={!isLoading ? altsPoolBalance : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={4}
            />
          }
          stakedBalance={
            <NumberFormat
              className="number"
              value={!isLoading ? altsPoolStakedBalance : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={4}
            />
          }
          unlockedReward={
            <NumberFormat
              className="number"
              value={!isLoading ? altsPoolUnlockedReward : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          lockedReward={
            <NumberFormat
              className="number"
              value={!isLoading ? altsPoolLockedReward : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          estimatedAPY={
            <NumberFormat
              className="number"
              value={!isLoading ? altsPoolEstimatedAPY : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          stakeFn={() => {
            setStakeBalance(altsPoolBalance)
            setSelectedPoolTitle('ALTS / WETH Pool')
            if (altsLiquidityReward) {
              setSelectedPool(altsLiquidityReward)
              setSelectedPoolToken(altsPoolToken)
            }
            setStakeShow(true)
          }}
          claimFn={() => claimRewards('ALTS')}
          exitFn={() => exitRewards('ALTS')}
        />
        <MiningCard
          addLiquidityLink={`https://app.sushi.com/add/0x613D5D57Ce31A8EB3d9AB9750F79dF3b57cC9F56/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619`}
          icon1={long}
          icon2={eth}
          tradingPair="LONG / WETH"
          dex="SushiSwap"
          dexLink="https://app.sushi.com/add/0x613D5D57Ce31A8EB3d9AB9750F79dF3b57cC9F56/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619"
          rewardsAmount={'4 million'}
          lpTokenBalance={
            <NumberFormat
              className="number"
              value={!isLoading ? longPoolBalance : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={4}
            />
          }
          stakedBalance={
            <NumberFormat
              className="number"
              value={!isLoading ? longPoolStakedBalance : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={4}
            />
          }
          unlockedReward={
            <NumberFormat
              className="number"
              value={!isLoading ? longPoolUnlockedReward : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          lockedReward={
            <NumberFormat
              className="number"
              value={!isLoading ? longPoolLockedReward : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          estimatedAPY={
            <NumberFormat
              className="number"
              value={!isLoading ? longPoolEstimatedAPY : '0.00'}
              displayType="text"
              thousandSeparator
              prefix=""
              decimalScale={2}
            />
          }
          stakeFn={() => {
            setStakeBalance(longPoolBalance)
            setSelectedPoolTitle('LONG / WETH Pool')
            if (longLiquidityReward) {
              setSelectedPool(longLiquidityReward)
              setSelectedPoolToken(longPoolToken)
            }
            setStakeShow(true)
          }}
          claimFn={() => claimRewards('LONG')}
          exitFn={() => exitRewards('LONG')}
        />
      </div>

      <br />
      <Stake
        pool={selectedPool}
        poolTitle={selectedPoolTitle}
        poolToken={selectedPoolToken}
        balance={stakeBalance}
        show={stakeShow}
        onHide={() => setStakeShow(false)}
        refresh={() => refresh()}
      />
      <br />
      <br />
      <br />
      <br />
    </div>
  )
}

export default Farm
